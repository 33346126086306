@import "../../variables";

.container {
  margin-top: 210px;

  .divider {
    max-width: 370px;
    border: 0.5px solid @main-color-cl-16;

    &.divider_container {
      @media (max-width: 1699px) {
        margin-top: 96px;
      }
    }
  }
}