@import "../../variables";

.container {
  z-index: 3;
  width: 100%;
  margin-top: auto;
  background: @main-color-cl-19 !important;
  .card {
    background: @main-color-cl-19;
    border: none;
    box-shadow: 2px 4px 20px @main-color-cl-17;
    text-align: left;
    .card_header {
      left: 50%;
      width: 75px;
      height: 40px;
      margin: 0 auto;
      transform: translate(0, -90%);
      background: inherit;
      border: none;
      border-radius: 10px;
      padding: 0;
      button {
        background: transparent;
        border: none;
        transform: rotate(-180deg);
        transition: @transition02;
        img {
          width: 17px;
        }
        &.isOpen {
          transform: rotate(0);
        }
      }
    }
    .card_body {
      height: 380px;
      display: flex;
      flex-direction: column;
      padding: 0 40px;
    }
  }
}


@media (min-width: 1400px) {
  .container_md {
    max-width: 1600px !important;
  }
}
