@import "./global";
.swiper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  pointer-events: none;
  & * {
    pointer-events: auto;
    .user-select-none()
  }
  .swiper-button {
    &-next, &-prev {
      position: absolute;
      pointer-events: none;
      height: 100%;
      width: 100%;
      &:after {
        content: '';
        position: absolute;
        height: 100%;
        width: 5%;
        top: 0;
        pointer-events: none;
        z-index: 5;
      }
    }
    &-prev:not(&-disabled):after {
      background: linear-gradient(270deg, rgba(238, 240, 245, 0) 25.96%,#F5F8FE 100%);
      left: 0;
    }
    &-next:not(&-disabled):after {
      background: linear-gradient(270deg, #F5F8FE 25.96%, rgba(238, 240, 245, 0) 100%);
      right: 0;
    }
  }
  .swiper-wrapper {
    display: flex;
    width: fit-content;
    .swiper-slide {
      width: auto !important;
    }
  }
}
