.footer {
  margin-bottom: 160px;
}
.footer img {
  width: 83px;
}
.footer hr {
  margin: 115px 0 80px 0;
}
.footer .short_line {
  background: #00205B;
  width: 40px;
  height: 4px;
  margin: 50px 0 40px;
  opacity: 1;
}
.footer span {
  color: #00205B;
  display: block;
  margin-bottom: 20px;
  font-weight: 500;
}
