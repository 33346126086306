@import "../../variables";

.box {
  padding: 24px 40px;
  h2 {
    font-weight: 300 !important;
  }
  .title {
    font-weight: 500 !important;
  }
  button, div.disabled_btn {
    margin-right: -40px;
    margin-top: -24px;
    height: 47px;
    width: 49px;
    min-width: auto;
    &:hover {
      background: transparent;
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
}