.inputContainer {
  cursor: pointer;
  height: 25px;
  width: auto;
}
.calendarIcon {
  width: 24px;
  height: 24px;
}
.input {
  width: 105px;
  height: 25px;
  color: #4A4A6A;
  font-size: 18px;
  background: none;
  border: none;
  outline: none;
}
.input:hover,
.input:focus,
.input:active {
  text-underline-offset: 1.5px;
  text-decoration-line: underline;
}
