.container {
  width: 200px;
  height: 200px;
  position: relative;
}
.container .avatar {
  cursor: pointer;
  width: 200px;
  height: 200px;
  transition: all 0.5s ease;
  border: 1px solid #C0C0CF;
}
.container .avatar:hover {
  opacity: 0.7;
}
.container .label input[type="file"] {
  display: none;
}
.container .editImg {
  cursor: pointer;
  width: 24px;
  height: 24px;
  position: absolute;
  bottom: -11px;
  right: 0;
}
