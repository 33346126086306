.back_btn {
  z-index: 2;
  min-width: 56px;
  min-height: 56px;
  border-radius: 50px;
  max-height: 56px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 15px;
  width: 56px !important;
  white-space: nowrap;
  overflow: hidden;
  transition: all 0.2s ease;
  border: none;
}
.back_btn.light {
  background: #F6F6F9;
}
.back_btn.dark {
  background: #00205B;
}
.back_btn img {
  display: block;
  height: 24px;
  width: 24px;
}
.back_btn span {
  margin: 0 15px 0 5px;
  font-weight: normal;
  font-size: 24px;
  opacity: 0;
  pointer-events: none;
  transition: all 0.2s ease;
  width: max-content;
}
.back_btn span.light {
  color: #00205B;
}
.back_btn span.dark {
  color: #F6F6F9;
}
.back_btn:hover {
  width: 135px !important;
}
.back_btn:hover span {
  opacity: 1;
}
