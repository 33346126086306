.container {
  position: absolute;
  display: flex;
  z-index: 1;
}
.container .tracker_name {
  width: 10px;
  font-size: 10px;
  padding: 0.25px 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  background: #00205B;
  height: 100%;
}
.container.status_good .tracker_name {
  background: #00205B;
}
.container.status_bad .tracker_name {
  background: #D02B20;
}
.container .tracker_temperature {
  height: 24px;
  padding: 0 5px;
  background: rgba(0, 32, 91, 0.2);
  border: 1px solid #363E47;
  box-sizing: border-box;
  backdrop-filter: blur(4px);
  border-radius: 0 3px 3px 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  font-size: 16px;
}
.container .tracker_temperature span {
  font-size: 11px;
  line-height: 11px;
  margin-bottom: 4px;
}
