@import "variables";
@import "animations";

.nivo-line-chart-points svg > g > g:nth-last-of-type(3),
.nivo-line-chart-separate-points svg > g > g:nth-last-of-type(8) {
  transform: translate(0, calc(100% - 55px)) !important;
  text {
    font-weight: 600;
  }
}

.nivo-line-chart-points {
  width: calc(100% + 40px);
  margin-right: -40px;
}
.nivo-line-chart-points,
.nivo-line-chart-separate-points {
  path, g > line {
    transition: stroke @transitionCustom05;
  }
  svg > g > g:not(.def-opacity-0) {
    animation: _show 1s;
    opacity: 1;
  }
}
.line-chart {
  &-tooltip-info,
  &-tooltip-value {
    background-color: white;
    padding: 2px 10px;
    border-radius: 2px;
    filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.25));
    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: -11px;
      width: 12px;
      height: 12px;
      background-color: inherit;
      transform: rotate(45deg) translate(-70%, 0);
      left: 50%;
    }
    span {
      color: @main-color-cl-01;
      font-size: @font-little-span;
      &:first-child {
        font-weight: 400;
      }
      &:last-child {
        font-weight: 600;
      }
    }
  }
  &-tooltip-info {
    background-color: @main-color-cl-01;
    display: none;
    width: fit-content;
    position: absolute;
    max-width: 300px;
    text-align: center;
    color: @main-color-cl-08;
    filter: none;
    transform: translate(-20%, -50%);
    transition: 1s ease;
  }
  &-legend {
    &-square {
      height: 16px;
      border-radius: 3px;
    }
    &-line {
      border-radius: 4px;
      height: 4px;
    }
    &-gusty_line {
      &:before {
        float: left;
        content: '';
        border-radius: 4px;
        height: 4px;
        width: 7px;
        background: linear-gradient(90deg, rgba(54, 113, 233, 0.51) 0%, rgba(54, 113, 233, 0.724) 100%);
      }
      &:after {
        float: right;
        content: '';
        border-radius: 4px;
        height: 4px;
        width: 7px;
        background: linear-gradient(90deg, rgba(54, 113, 233, 0.786) 0%, rgba(54, 113, 233, 1) 100%);
      }
    }
    &-circle {
      border-radius: 50%;
      height: 16px;
    }
    &-square,
    &-line,
    &-gusty_line,
    &-circle {
      width: 16px;
      margin-right: 10px;
      ~ div > span {
        line-height: 100%;
      }
    }
  }
}
line ~ .show-line {
  opacity: 0;
}

#prediction_fill {
  fill: url(#prediction_gradient);
}
#prediction_gradient {
  stop:first-child {
    stop-color: #0000FFCC;
  }
  stop:last-child {
    stop-color: #0000FF00;
  }
}