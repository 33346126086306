@import "../../variables";

.checkbox {
  display: block;
  .label_style {
    width: 100%;
    display: flex;
    color: @main-color-cl-10;
    &:after {
      content: '';
      width: 24px;
      height: 24px;
      background: @main-color-cl-19;
      border: 1px solid @main-color-cl-16;
      box-sizing: border-box;
      border-radius: 4px;
      display: inline-block;
      transition: @transition02;
    }
    &:before {
      content: '';
      position: absolute;
      width: 24px;
      height: 24px;
      right: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: url("../../../icons/chec_on.svg") no-repeat center;
      transition: @transition02;
      opacity: 0;
    }
    &.left_checkbox_position {
      flex-direction: row-reverse;
      &:after {
        margin-right: 10px;
      }
    }
    &.right_checkbox_position {
      &:after {
        margin-left: auto;
      }
    }
  }
  .input_style:checked ~ .label_style {
    &:after {
      background: @main-color-cl-01;
    }
    &:before {
      opacity: 1;
    }
  }
  .input_style {
    display: none;
  }
}
