.card {
  width: 364px;
  height: 575px;
  background: #FFFFFF;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 15px;
  color: #00205B;
}
.card .header {
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 18px;
  margin-left: 5px;
}
.card .img_wrap {
  background: #C0C0CF;
  border-radius: 8px;
  overflow: hidden;
  min-height: 334px;
  margin-bottom: auto;
}
.card .wrap_temperature_box {
  margin-top: -30px;
  min-height: 100px;
}
.card .wrap_temperature_box > div {
  text-align: center;
  width: 100%;
}
.card .wrap_temperature_box .one_temp_box {
  width: fit-content;
  margin: 0 auto;
}
.cursor_share {
  cursor: url("../../../icons/Hover_icon_open.svg"), auto;
}
