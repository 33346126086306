.img360container {
  margin-left: auto;
  margin-bottom: 40px;
  width: 370px;
  height: 220px;
  padding: 4px;
  background: white;
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}
@media (max-width: 1200px) {
  .img360container {
    width: 265px;
    height: 160px;
  }
}
@media (max-width: 1024px) {
  .img360container {
    margin-left: auto;
    margin-bottom: 20px;
    width: 80px;
    height: 58px;
    border-radius: none;
  }
}
.img360container .img360wrap {
  height: 100% !important;
  overflow: hidden;
  border-radius: 10px !important;
  position: relative !important;
  background: #F6F8FC;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  z-index: 1;
}
.img360container .btn360 {
  display: none;
}
@media (max-width: 1024px) {
  .img360container .btn360 {
    display: block;
    text-align: center;
    padding: 15px 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #00205B;
    color: #FFFFFF;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    z-index: 9;
  }
}
.imageHotspot {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
.modalContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
