@import "../variables";

.confirm_form_wrap {
  height: calc(100vh - 300px);
  padding-bottom: 150px;
  .confirm_form {
    background: @main-color-cl-08;
    box-shadow: @main-form-shadow;
    border-radius: @main-form-br;
    padding: 60px 80px 80px;
    h2 {
      color: @main-color-cl-11 !important;
      margin-bottom: 50px;
      font-weight: bold;
    }
    .label {
      color: @main-color-cl-11 !important;
      font-weight: bold;
    }
    .error_text {
      color: @main-color-cl-21;
      position: relative;
      display: block;
      text-align: center;
      margin: 5px 0 -20px;
    }
  }
}
