@import "../../variables";

.status {
  border-radius: 10px;
  padding: 4.5px 3.5px;
  transition: @transition02;
  &.Pending {
    background: rgba(#0000FF, 0.15);
    color: #0000FF;
  }
  &.Active {
    background: rgba(@main-color-cl-31, 0.15);
    color: #00BC4B;
  }
  &.Disabled {
    background: rgba(@main-color-cl-21, 0.15);
    color: @main-color-cl-21;
  }
}
.table_content {
  height: 96px;
  display: table-row !important;
  color: @main-color-cl-01 !important;
  cursor: auto !important;
  > td {
    transition: @transition02;
    color: @main-color-cl-01 !important;
    &:first-child {
      border: none;
      border-top-left-radius: @btn-br;
      border-bottom-left-radius: @btn-br;
    }
    &:last-of-type {
      border-top-right-radius: @btn-br;
      border-bottom-right-radius: @btn-br;
    }
  }
  &:last-child > td {
    border: none !important;
  }
  > span {
    border-radius: @btn-br;
  }
  button {
    color: @main-color-cl-01;
    cursor: pointer;
    min-width: auto;
    text-transform: none;
    &:hover {
      background: transparent;
    }
    > div {
      pointer-events: none;
      color: inherit;
    }
    img {
      width: 24px;
    }
  }
  .select {
    margin-right: 5px;
    div {
      padding-right: 0 !important;
    }
    svg {
      display: none;
    }
    fieldset {
      border: none;
    }
  }
  .role {
    &:hover, &:active, &:focus {
      .select div {
        text-decoration: underline;
      }
    }
  }
  &:hover > td {
    background: @main-color-cl-07;
    border-color: transparent;
  }
}