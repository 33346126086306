.temperature_wrap {
  display: flex;
}
.temperature_wrap span:first-child {
  font-size: 40px;
  font-weight: 400;
  line-height: normal;
}
.temperature_wrap span:last-child {
  color: #8E8EA9;
  font-size: 24px;
  display: block;
  margin-bottom: auto;
  margin-top: 1px;
  margin-left: 1px;
}
.temperature_wrap ~ span {
  color: #8E8EA9;
}
