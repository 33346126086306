.tables_wrap {
  display: grid;
  gap: 0 40px;
}
@media (max-width: 993px) {
  .tables_wrap {
    grid-template-columns: 1fr;
  }
}
@media (min-width: 992px) {
  .tables_wrap {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
}
.tables_wrap > * {
  height: max-content;
}
.error_status {
  color: #D02B20;
}
