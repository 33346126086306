@keyframes _show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes _hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes _fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes pulse {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(0.7);
    opacity: 0;
  }
}
#textInfo .hotspot .in {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  transition: transform ease-out 0.1s, background 0.2s;
}
#textInfo .hotspot .out {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 5px solid rgba(255, 255, 255, 0.8);
  transition: transform ease-out 0.1s, border 0.2s;
  box-sizing: border-box;
}
#textInfo .hotspot .out:after {
  top: 0;
  left: 0;
  padding: 0;
  z-index: -1;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1);
  opacity: 0;
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: '';
  box-sizing: content-box;
  transform: scale(0.9);
}
#textInfo .hotspot:hover .in {
  background-color: #ffffff;
  transform: scale(0.8);
}
#textInfo .hotspot:hover .out {
  border: 5px solid #ffffff;
  transform: scale(0.9);
  color: #FFFFFF;
}
#textInfo .hotspot:hover .out:after {
  -webkit-animation: sonarEffect 1.2s ease-out;
  -moz-animation: sonarEffect 1.2s ease-out;
  animation: sonarEffect 1.2s ease-out;
}
.custom-hotspot > .hotspot {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background: url(../../../icons/hotspot-icon.svg) no-repeat 50% 50% #00205B;
  background-size: 45px 45px;
  animation: none;
  cursor: pointer;
}
.custom-hotspot > .hotspot > div {
  visibility: hidden;
}
.custom-hotspot > .hotspot:after,
.custom-hotspot > .hotspot:before {
  content: '';
  position: absolute;
  border: 2px solid #1CE783;
  left: -30px;
  opacity: 0;
  right: -30px;
  top: -30px;
  bottom: -30px;
  border-radius: 50%;
  animation: pulse 2s linear infinite;
}
.custom-hotspot > .hotspot:after {
  animation-delay: 1.25s;
}
@-webkit-keyframes sonarEffect {
  0% {
    opacity: 0.5;
  }
  20% {
    opacity: 0.8;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px rgba(37, 170, 225, 0.8), 0 0 0 10px rgba(255, 255, 255, 0.5);
  }
  100% {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px rgba(37, 170, 225, 0.8), 0 0 0 10px rgba(255, 255, 255, 0.5);
    -webkit-transform: scale(1.5);
    opacity: 0;
  }
}
@-moz-keyframes sonarEffect {
  0% {
    opacity: 0.5;
  }
  20% {
    opacity: 0.8;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px rgba(37, 170, 225, 0.8), 0 0 0 10px rgba(255, 255, 255, 0.5);
  }
  100% {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px rgba(37, 170, 225, 0.8), 0 0 0 10px rgba(255, 255, 255, 0.5);
    -moz-transform: scale(1.5);
    opacity: 0;
  }
}
@keyframes sonarEffect {
  0% {
    opacity: 0.5;
  }
  20% {
    opacity: 0.8;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px rgba(37, 170, 225, 0.8), 0 0 0 10px rgba(255, 255, 255, 0.5);
  }
  100% {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px rgba(37, 170, 225, 0.8), 0 0 0 10px rgba(255, 255, 255, 0.5);
    transform: scale(1.5);
    opacity: 0;
  }
}
