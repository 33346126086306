@import "functions";
@import "variables";
@import "animations";
@import "scrolling-carousel";
@import 'global';
@import 'transitionSettings';
@import './components/Inputs/Selector';

body {
  margin: 0;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  font-family: @main-font-family !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

h1 {
  font-size: @font-h1;
  color: @main-color-cl-01 !important;
  font-weight: 400;
}

h2 {
  color: @main-color-cl-01 !important;
}

h3 {
  font-size: @font-p !important;
  line-height: 26px !important;
  color: @main-color-cl-01 !important;
}
h5 {
  font-size: @font-h5 !important;
  line-height: 49px !important;
  font-weight: 400;
  color: @main-color-cl-01 !important;
}
span {
  font-size: @font-span;
}
p {
  font-size: @font-p;
}
small {
  font-size: @font-small;
}

button {
  &:focus, &:active {
    box-shadow: none !important;
  }
}

// ------- tooltip -----------
.tooltip {
  .tooltip-inner {
    padding: 5px;
    background: @main-color-cl-02;
    color: @main-color-cl-08;
    max-width: 210px;
  }
  &[data-popper-placement='top'] .tooltip-arrow:before {
    border-top-color: @main-color-cl-02;
  }
}
// ------- end tooltip -----------

// ------- sensor card & temperature circle -----------
.sensor-card {
  .time-box {
    transition: @transition05;
    opacity: 0;
    &.time-box-for-hover {
      opacity: 1;
    }
  }
}


#bg_heizkessel_on {
  position: absolute;
  transition: opacity 5s;
}

.fadeTo {
  &_9000 {
    &_1 {
      animation: _show 9s;
      opacity: 1;
    }
    &_0 {
      animation: _hide 9s;
      opacity: 0;
    }
  }
  &_5000 {
    &_1 {
      animation: _show 5s;
      opacity: 1;
    }
    &_0 {
      animation: _hide 5s;
      opacity: 0;
    }
  }
}
// ------- end  sensor card & temperature circle -----------

// ------- tabs -----------
.tabs {
  justify-content: center;
  flex-wrap: nowrap;
  border: none;
  transition: @transition05;
  & > li button {
    color: @main-color-cl-01 !important;
    border: none;
    // font-weight: bold;
    border-width: 0 !important;
    border-bottom: 2px solid @main-color-cl-01 !important;
    opacity: 0.25;
    font-size: @font-little-span;
    font-weight: 400;
    transition: @transition05;
    &[aria-selected='true'] {
      opacity: 1;
      font-weight: 500;
    }
  }
}

.detail_card > li button{
  font-size: @font-h7;
  font-weight: 400;
}

.tab {
  transition: @transition05;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.disable_tab {
  display: none;
}
// ------- end tabs -----------


// ------- ? -----------
.row {
  --bs-gutter-x: 0;
}
// ------- end ? -----------

// ------- slider -----------
.slick-slider {
  position: relative;
  background: white;
  > * {
    outline: none;
  }
  .slick-track {
    display: flex;
    flex-direction: row;
    img {
      border-radius: 10px;
    }
  }
  .slick-arrow {
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translate(0, -50%);
    width: 40px;
    height: 40px;
    background: @main-color-cl-19;
    opacity: 0.85;
    border-radius: 26.6667px;
    border: none;
    transition: @transition02;
    svg {
      color: @main-color-cl-01
    }
    &.slick-prev {
      left: 8px;
      padding-right: 8px;
      svg {
        transform: rotate(180deg);
      }
    }
    &.slick-next {
      right: 8px;
      padding-left: 8px;
    }
    &.slick-disabled {
      opacity: 0;
      pointer-events: none;
    }
    &:hover {
      opacity: 1;
    }
  }
  .slick-dots {
    position: absolute;
    display: flex !important;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 0;
    margin: 0;
    padding: 0;
    li {
      display: block;
      button {
        visibility: hidden;
        width: 8px;
        height: 8px;
        padding: 0;
        margin-right: 4px;
        &:after {
          content: '';
          display: block;
          width: inherit;
          height: inherit;
          background: @main-color-cl-19;
          position: absolute;
          top: 0;
          visibility: visible;
          border-radius: 50%;
        }
      }
      &.slick-active {
        button:after {
          background: @main-color-cl-01;
        }
      }
    }
  }
}
.lg-container {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
}
// ------- end slider -----------

// ------- ? -----------
.details_card.nav-tabs .nav-link {
  font-size: @font-p;
}
// ------- end ? -----------

// ------- calendar -----------
.MuiCalendarPicker-root {
  .MuiTypography-caption {
    margin: 0;
  }
  .css-1n2mv2k, .css-mvmu1r {
    margin-left: 40px;
  }
  .MuiPickersDay-root {
    line-height: 100%;
  }
  .css-x1wgng-MuiYearPicker-root,
  .css-1wvgxus-MuiCalendarPicker-viewTransitionContainer {
    min-height: 304px;
    align-items: baseline;
  }
  .css-qtzvnr-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected) {
    border: none;
  }
  .Mui-selected {
    background-color: white !important;
    color: #000000DD !important;
    &:hover {
      background-color: #0000000A !important;
    }
  }
}
// ------- end calendar -----------

// ------- 360 -----------
.pnlm-ui {
  .pnlm-load-box {
    display: none !important;
  }
}

.pnlm-container {
  background: @main-color-cl-07 !important;
  transition: @transition05;
}

.pnlm-controls  {
  display: none !important;
}

.pnlm-hotspot-base:not(.custom-hotspot) {
  background: none;
  > .hotspot {
    transform: translate(-62%, -29%);
  }
}

.isNotFullScreen .hotspot {
  zoom: .6;
  transform: translate(-22%, -16%);
}
// ------- end 360 -----------

.modal-container {
  max-width: 350px;
  min-width: 200px !important;
}