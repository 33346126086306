.checkbox {
  display: block;
}
.checkbox .label_style {
  width: 100%;
  display: flex;
  color: #212134;
}
.checkbox .label_style:after {
  content: '';
  width: 24px;
  height: 24px;
  background: #F6F6F9;
  border: 1px solid #C0C0CF;
  box-sizing: border-box;
  border-radius: 4px;
  display: inline-block;
  transition: all 0.2s ease;
}
.checkbox .label_style:before {
  content: '';
  position: absolute;
  width: 24px;
  height: 24px;
  right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../../../icons/chec_on.svg") no-repeat center;
  transition: all 0.2s ease;
  opacity: 0;
}
.checkbox .label_style.left_checkbox_position {
  flex-direction: row-reverse;
}
.checkbox .label_style.left_checkbox_position:after {
  margin-right: 10px;
}
.checkbox .label_style.right_checkbox_position:after {
  margin-left: auto;
}
.checkbox .input_style:checked ~ .label_style:after {
  background: #00205B;
}
.checkbox .input_style:checked ~ .label_style:before {
  opacity: 1;
}
.checkbox .input_style {
  display: none;
}
