.swiperContainer {
  visibility: visible;
  position: absolute !important;
  display: flex;
  flex-direction: column;
  z-index: 9;
  bottom: 0;
  width: calc(100% - 72px) !important;
  height: auto;
  margin: 36px;
  pointer-events: none;
}
.swiperContainer * {
  pointer-events: auto;
}
.swiperContainer .btnContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  pointer-events: none;
}
.swiperContainer .btnContainer * {
  pointer-events: auto;
}
.swiperContainer .btnContainer .selectBtn {
  width: 90px;
  height: 30px;
  text-align: center;
  border-radius: 40px;
  border: none;
  font-weight: 600;
  font-size: 16px;
  transition: all 0.5s ease;
}
.swiperContainer .btnContainer .selectBtn:hover {
  background: #00205B;
  color: #FFFFFF;
}
.swiperContainer .btnContainer .selectBtn:first-child {
  margin-right: 14px;
}
.swiperContainer .btnContainer .activeBtn {
  background: #00205B;
  color: #FFFFFF;
}
.swiperContainer .btnContainer .unActiveBtn {
  background: #FFFFFF;
  color: #00205B;
}
@media (min-width: 2560px) {
  .swiperContainer .btnContainer {
    margin-bottom: 25px;
  }
  .swiperContainer .btnContainer .selectBtn {
    font-size: 21px;
    width: 150px;
    height: 50px;
  }
}
.swiperContainer .cardContainer {
  box-sizing: border-box;
  justify-content: space-between;
  padding: 8px;
  width: 208px;
  height: 148px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #FFFFFF;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 2px solid transparent;
}
.swiperContainer .cardContainer h3 {
  color: #00205B;
  font-weight: 600;
  font-size: 14px !important;
}
.swiperContainer .cardContainer .image360 {
  width: 188px;
  border-radius: 8px;
}
.swiperContainer .cardContainer.active {
  border-color: #2D78C6;
}
.swiperContainer .cardContainer.unActive {
  opacity: 0.6;
}
.swiperContainer .cardContainer:hover {
  opacity: 1;
}
@media (min-width: 2560px) {
  .swiperContainer .cardContainer {
    padding: 15px;
    width: 300px;
    height: 200px;
  }
  .swiperContainer .cardContainer h3 {
    font-size: 18px !important;
  }
  .swiperContainer .cardContainer .image360 {
    width: 270px;
  }
}
.hiddenContainer {
  visibility: hidden;
}
