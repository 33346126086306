@keyframes _show {
  from { opacity: 0 }
  to { opacity: 1 }
}

@keyframes _hide {
  from { opacity: 1 }
  to { opacity: 0 }
}

@keyframes _fade {
  0% { opacity: 1 }
  50% { opacity: 0 }
  100% { opacity: 1 }
}

@keyframes pulse {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% { opacity: 1 }
  100% {
    transform: scale(0.7);
    opacity: 0;
  }
}
