body {
  background: #F6F8FC;
}
.navbar {
  z-index: 4;
  width: 100%;
  pointer-events: none;
}
.navbar .container {
  min-height: 88px;
}
.navbar .container a,
.navbar .container button {
  pointer-events: all;
}
@media (min-width: 1400px) {
  .navbar .container,
  .navbar .container-lg,
  .navbar .container-md,
  .navbar .container-sm,
  .navbar .container-xl,
  .navbar .container-xxl {
    max-width: calc(100% - 120px);
  }
}
