@import "../../variables";

.wrap_card {
  background: @main-color-cl-08;
  border-radius: 10px;
  color: @main-color-cl-01;
  transition: opacity @transitionCustom05;
  text-align: start;
  width: auto;
  height: auto;
  box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
  padding: 0;
  overflow: hidden;
  /* -------------- CARD -------------- */
  .card {
    width: 655px;
    height: 562px;
    .btn_close {
      position: absolute;
      right: 25px;
      width: 34px;
      height: 34px;
    }
    .h1 {
      font-size: 18px;
      font-weight: 500;
      padding: 15px 0 35px 25px;
      button {
        top: 5px;
      }
    }
    .h2 {
      font-size: 24px;
      text-align: center;
      text-transform: uppercase;
      margin-top: -20px;
    }
    .oeffnungswert {
      text-align: center;
      font-weight: 300;
      margin-top: -15px;
      font-size: 64px !important;
      span.temperature,
      span.range_value {
        text-align: center;
        font-weight: 300;
        font-size: 64px !important;
        margin: -15px 15px 0;
      }
      .prozent {
        font-size: 28px;
        color: @main-color-cl-14;
        font-weight: 500;
        position: absolute;
        margin: 18px 0 0 -8px;
        &.to_bottom {
          position: relative;
          margin: 0 0 0 -8px;
        }
      }
    }
    .range_slider {
      text-align: center;
      height: 256px;
      /*    border:1px solid #000;*/
    }
    .input_range {
      height: 239px;
    }
    .vorlauf_container {
      position: absolute;
      width: 200px;
      height: 150px;
      top: 300px;
      left: 60px;
      text-align: center;
      /*    border:1px solid #000;*/
      text-transform: uppercase;
    }
    .temp_rising {
      margin-right: 0;
      margin-top: -10px;
    }
    .ruecklauf_container {
      position: absolute;
      width: 200px;
      height: 150px;
      top: 300px;
      right: 60px;
      text-align: center;
      /*    border:1px solid #000;*/
      text-transform: uppercase;
    }
    .divider {
      padding: 0 25px 0 25px;
    }
    .bottomrow {
      p {
        font-size: 24px;
        text-align: center;
        .delta_value, .volumenstrom_value {
          font-size: 24px !important;
          text-align: center;
        }
      }
      .einheit {
        font-size: 18px;
        color: @main-color-cl-14;
        margin-left: 8px;
      }
      .einheitenbeschreibung {
        font-size: 16px;
        color: @main-color-cl-14;
        text-transform: uppercase;
        margin-top: -15px;
      }
    }
  }

  hr.solid {
    border-top: 1px solid #bbb;
  }

  .streetbar {
    background: @main-color-cl-08;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
    margin-bottom: 100px;
    .strasse {
      font-size: 36px;
      margin-top: 54px;
    }
    .ort {
      font-size: 36px;
      font-weight: 300;
      margin-top: -15px;
    }
  }

  .liegenschaft_thumbnail {
    padding: 16px;
  }

  .bottomrow .temp_arrow_decreasing {
    margin-right: 3px;
    width: 8px;
  }

  .arrow_red_increasing {
    width: 10px;
    height: 33px;
    display: inline-flex;
    vertical-align: 5px;
    position: absolute;
    & > div {
      overflow: inherit !important;
    }
  }


  input[type=range] {
    width: 37%;
    height: 6px !important;
    transform: translate(0, 90px) rotate(-90deg);
  }
  .input_range {
    -webkit-appearance: none;
    height: 100%;
    width: 6px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
    background-image: linear-gradient(#00205B, #00205B);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    &:hover { opacity: 1; }
    &:focus { outline: none; }
    &::-webkit-slider-thumb {
      border: 2px solid @main-color-cl-01;
      -webkit-appearance: none;
      appearance: none;
      //border: 0;
      border-radius: 50%;
      cursor: pointer;
      width: 20px;
      height: 20px;
      left: 951px;
      top: 386px;
      background: @main-color-cl-19;
      box-shadow: 1.95943e-16px 3.2px 6.4px rgba(49, 49, 50, 0.17), inset -5.22516e-17px -0.853333px 0.853333px #E8E8EB, inset 5.22516e-17px 0.853333px 0.853333px #FFFFFF;
    }
    &::-moz-range-thumb {
      border: 2px solid @main-color-cl-01;
      -webkit-appearance: none;
      appearance: none;
      border-radius: 50%;
      cursor: pointer;
      width: 16px;
      height: 16px;
      background: @main-color-cl-19;
      box-shadow: 1.95943e-16px 3.2px 6.4px rgba(49, 49, 50, 0.17), inset -5.22516e-17px -0.853333px 0.853333px #E8E8EB, inset 5.22516e-17px 0.853333px 0.853333px #FFFFFF;
    }
    //&::-moz-range-thumb {
    //  width: 23px;
    //  height: 24px;
    //  border: 0;
    //  background: url('../../../images/performance/prototype/slider_thumb.png');
    //  cursor: pointer;
    //}
  }
}
