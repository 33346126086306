.login_form_wrap {
  height: calc(100vh - 300px);
  padding-bottom: 150px;
}
.login_form_wrap .login_form {
  background: #FFFFFF;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 80px;
}
.login_form_wrap .login_form h2 {
  color: #32324D !important;
  margin-bottom: 50px;
  font-weight: bold;
}
.login_form_wrap .login_form .label {
  color: #32324D !important;
  font-weight: bold;
}
.login_form_wrap .login_form .help_links *:first-child {
  margin-right: 40px;
  position: relative;
}
.login_form_wrap .login_form .help_links *:first-child:after {
  content: '';
  position: absolute;
  height: 100%;
  width: 1px;
  background: #00205B;
  top: 0;
  margin-left: 40px;
}
.login_form_wrap .login_form .help_links a {
  color: #32324D !important;
  font-weight: bold;
}
.login_form_wrap .login_form .help_links *:last-child {
  margin-left: 40px;
}
.login_form_wrap .login_form .error_text {
  color: #D02B20;
  position: relative;
  display: block;
  text-align: center;
  margin: -15px 0 20px;
}
