.container {
  margin-top: 65px;
}
.container .grid {
  width: 788px;
}
.container .grid .grid_item {
  margin-top: 11px;
}
@media (max-width: 1699px) {
  .container .grid_container {
    margin: 17px 0 0 394px;
  }
}
.container .profileImage {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 1px solid #C0C0CF;
}
