body {
  background: #F6F8FC;
}
@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: calc(100% - 120px);
  }
}
.nav_light {
  background: none !important;
}
