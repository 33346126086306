input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"] {
  background: #F3F3F3;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid transparent;
  width: 400px;
  height: 52px;
  transition: all 0.2s ease;
}
input[type="text"]:hover,
input[type="number"]:hover,
input[type="email"]:hover,
input[type="password"]:hover,
input[type="text"]:active,
input[type="number"]:active,
input[type="email"]:active,
input[type="password"]:active,
input[type="text"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="password"]:focus {
  border-color: #C0C0CF;
  background: #F3F3F3;
  box-shadow: none;
}
.btn-primary {
  background: #00205B !important;
  border-radius: 8px !important;
  height: 52px;
  color: #FFFFFF !important;
  border: none !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
}
.btn-primary:hover {
  background-color: #002B7A !important;
}
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  box-shadow: none !important;
}
.btn-primary:disabled {
  cursor: no-drop;
  opacity: 0.5;
}
.btn-secondary {
  background: #F6F6F9 !important;
  border-radius: 8px !important;
  height: 52px;
  color: #666687 !important;
  border: none !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
}
.btn-secondary:hover {
  background-color: #EAEAEF !important;
}
.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:focus {
  box-shadow: none !important;
}
.btn-secondary:disabled {
  cursor: no-drop;
  opacity: 0.5;
}
.btn-b {
  height: 52px;
}
.btn-m {
  height: 40px;
}
.btn-s {
  height: 30px;
}
.btn-modal-primary {
  width: 125px;
  height: 40px;
}
.btn-modal-secondary {
  width: 125px;
  height: 40px;
  border: 1px solid #666687 !important;
  background-color: transparent;
}
.input-modal {
  width: 100% !important;
}
a {
  color: #32324D !important;
  text-decoration: none !important;
}
.flex {
  display: flex;
}
.flex-imp {
  display: flex !important;
}
.flex-top-center {
  display: flex;
  align-items: center;
}
.flex-top-center-imp {
  display: flex !important;
}
.flex-top-center {
  display: flex;
  align-items: center;
}
.flex-top-center-imp {
  display: flex !important;
}
@media (max-width: 993px) {
  .flex-top-center-tablet {
    display: flex !important;
    align-items: center !important;
  }
  .flex-top-center-tablet-imp {
    display: flex !important;
  }
}
.flex-left-center {
  display: flex;
  justify-content: center;
}
.flex-left-center-imp {
  display: flex !important;
}
.flex-left-center {
  display: flex;
  justify-content: center;
}
.flex-left-center-imp {
  display: flex !important;
}
@media (max-width: 993px) {
  .flex-left-center-tablet {
    display: flex !important;
    justify-content: center !important;
  }
  .flex-left-center-tablet-imp {
    display: flex !important;
  }
}
@media (min-width: 992px) {
  .flex-left-center-desktop {
    display: flex !important;
    justify-content: center !important;
  }
  .flex-left-center-desktop-imp {
    display: flex !important;
  }
}
.flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}
.flex-center-imp {
  display: flex !important;
}
.flex-center-imp {
  display: flex !important;
}
@media (max-width: 993px) {
  .flex-center-tablet {
    display: flex !important;
    align-items: center !important;
  }
  .flex-center-tablet-imp {
    display: flex !important;
  }
}
.flex-center-imp {
  display: flex !important;
}
.flex-center-imp {
  display: flex !important;
}
@media (max-width: 993px) {
  .flex-center-tablet {
    display: flex !important;
    justify-content: center !important;
  }
  .flex-center-tablet-imp {
    display: flex !important;
  }
}
@media (min-width: 992px) {
  .flex-center-desktop {
    display: flex !important;
    justify-content: center !important;
  }
  .flex-center-desktop-imp {
    display: flex !important;
  }
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-column-imp {
  display: flex !important;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-column-imp {
  display: flex !important;
}
@media (max-width: 993px) {
  .flex-column {
    align-items: center;
  }
}
@media (max-width: 993px) {
  .flex-column-tablet {
    display: flex !important;
    flex-direction: column !important;
  }
  .flex-column-tablet-imp {
    display: flex !important;
  }
}
@media (max-width: 560px) {
  .flex-column-mobile {
    display: flex !important;
    flex-direction: column !important;
  }
  .flex-column-mobile-imp {
    display: flex !important;
  }
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-row-imp {
  display: flex !important;
}
@media (min-width: 992px) {
  .flex-row-d {
    display: flex;
    align-items: center;
    max-width: 990px;
  }
  .flex-row-d-imp {
    display: flex !important;
  }
  .flex-row-d > * {
    margin-left: 5px;
    margin-right: 5px;
  }
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.flex-between-imp {
  display: flex !important;
}
@media (min-width: 992px) {
  .flex-between-desktop {
    display: flex;
    justify-content: space-between;
  }
  .flex-between-desktop-imp {
    display: flex !important;
  }
}
.flex-evenly {
  display: flex;
  justify-content: space-evenly;
}
.flex-evenly-imp {
  display: flex !important;
}
.flex-around {
  display: flex;
  justify-content: space-around;
}
.flex-around-imp {
  display: flex !important;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}
.flex-end-imp {
  display: flex !important;
}
.display-none {
  display: none;
}
.h-500 {
  height: 500px;
}
.min-h-200 {
  min-height: 200px;
}
.py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}
.py-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}
.px-7 {
  padding-left: 7px !important;
  padding-right: 7px !important;
}
.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.px-25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}
.pt-15 {
  padding-top: 15px;
}
.pt-50 {
  padding-top: 50px;
}
.pt-70 {
  padding-top: 70px;
}
.pb-40 {
  padding-bottom: 40px;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-24 {
  margin-bottom: 24px !important;
}
.mb-35 {
  margin-bottom: 35px !important;
}
.mb-40 {
  margin-bottom: 40px !important;
}
.mb-50 {
  margin-bottom: 50px !important;
}
.mb-70 {
  margin-bottom: 70px !important;
}
.mb-120 {
  margin-bottom: 120px !important;
}
.mb-140 {
  margin-bottom: 140px !important;
}
.fw-normal {
  font-weight: normal;
}
.fw-bold {
  font-weight: 600;
}
.text-keft {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-initial {
  text-align: initial;
}
.cursor-pointer {
  cursor: pointer;
}
.pointer-event-none {
  pointer-events: none;
}
.user-select-none {
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}
.user-select-auto {
  -moz-user-select: auto;
  -khtml-user-select: auto;
  user-select: auto;
}
.user-select-none {
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}
.user-select-auto {
  -moz-user-select: auto;
  -khtml-user-select: auto;
  user-select: auto;
}
.color-red {
  color: #D02B20;
}
.color-main-color-primary {
  color: #00205B;
}
.color-secondary-color {
  color: #32324D;
}
.bg-color-main-color-form-bg {
  background-color: #FFFFFF;
}
.b-shadow {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}
.container-box {
  border-radius: 10px;
  width: 100%;
}
.container-box-shadow {
  border-radius: 10px;
  width: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}
.container-header {
  margin: 0 0 -10px;
  font-weight: 500;
  color: #00205B;
}
.listItem {
  border-bottom: 1px solid #EAEAEF;
  min-height: 40px;
}
.listItem > div[role="button"] {
  min-height: inherit;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.listItem span {
  font-size: 16px !important;
  text-transform: capitalize;
}
.opacity-0 {
  opacity: 0 !important;
}
.opacity-1 {
  opacity: 1 !important;
}
.lg-container .lg-outer {
  border-radius: 8px;
}
.lg-container.lg-show.lg-show-in.lg-inline {
  position: relative;
  height: 100%;
  width: 100%;
}
.lg-container.lg-show.lg-show-in.lg-inline .lg-fullscreen {
  display: none;
}
.lg-container.lg-show.lg-show-in.lg-inline .lg-backdrop {
  background: transparent;
}
.lg-container .lg-toolbar {
  opacity: 0;
  transition: opacity 0.5s;
}
.lg-container .lg-content {
  top: 0 !important;
}
.lg-container .lg-pager-outer {
  margin-bottom: 16px;
}
.lg-container .lg-pager-cont {
  margin: 0 2px !important;
}
.lg-container .lg-pager-cont > span {
  box-shadow: none !important;
  width: 8px !important;
  height: 8px !important;
  background: #F6F6F9 !important;
}
.lg-container .lg-pager-cont.lg-pager-active > span {
  background: #00205B !important;
}
.lg-container .lg-next,
.lg-container .lg-prev {
  width: 40px;
  height: 40px;
  background: #F6F6F9;
  opacity: 0.85 !important;
  border-radius: 50%;
  border: none;
  transition: all 0.2s ease;
}
.lg-container .lg-next:hover,
.lg-container .lg-prev:hover {
  opacity: 1 !important;
}
.lg-container .lg-next:before,
.lg-container .lg-prev:after {
  content: '';
  width: 18px;
  height: 11px;
  display: block;
}
.lg-container .lg-prev {
  left: 10px;
}
.lg-container .lg-prev:after {
  background: url("../../icons/arrow.svg") no-repeat;
  transform: rotate(90deg);
  margin-right: 2px;
}
.lg-container .lg-next {
  right: 10px;
}
.lg-container .lg-next:before {
  background: url("../../icons/arrow.svg") no-repeat;
  transform: rotate(270deg);
  margin-left: 2px;
}
.lg-container .lg-pager-thumb-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 0;
  border: 5px solid white;
}
.lg-container .lg-pager-thumb-cont img {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: initial;
}
.lg-container .lg-img-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}
.lg-container .lg-img-wrap img {
  width: 100%;
  height: auto;
  max-height: initial;
  pointer-events: none;
}
.lg-container .lg-maximize {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00205B;
  border-radius: 8px;
  width: 36px;
  height: 36px;
  margin: 10px;
}
.lg-container .lg-maximize:after {
  content: '';
  background: url('../../icons/fullscreen.svg') no-repeat;
  display: block;
  width: 24px;
  height: 24px;
}
.lg-container:not(.lg-container.lg-inline) .lg-maximize:after {
  background: url('../../icons/smallscreen.svg') no-repeat 50% 50%;
}
.lg-container:not(.lg-container.lg-inline) .lg-img-wrap img {
  width: auto;
  height: auto;
}
.lg-container:hover .lg-toolbar {
  opacity: 1;
  transition: opacity 0.2s;
}
.fullscreen {
  transform: inherit !important;
}
