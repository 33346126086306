@import "../../variables";

.card {
  width: 364px;
  height: 575px;
  background: @main-color-cl-08;
  box-shadow: @main-form-shadow;
  border-radius: @main-form-br;
  padding: 15px;
  color: @main-color-cl-01;
  .header {
    margin-bottom: 15px;
    font-weight: 500;
    font-size: @font-p;
    margin-left: 5px;
  }
  .img_wrap {
    background: @main-color-cl-16;
    border-radius: 8px;
    overflow: hidden;
    min-height: 334px;
    margin-bottom: auto;
  }
  .wrap_temperature_box {
    margin-top: -30px;
    min-height: 100px;
    > div {
      text-align: center;
      width: 100%;
    }
    .one_temp_box {
      width: fit-content;
      margin: 0 auto;
    }
  }

}
.cursor_share {
  cursor: url("../../../icons/Hover_icon_open.svg"), auto;
}