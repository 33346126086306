body {
  background: #F6F8FC !important;
}
.detail_view_wrap {
  position: sticky !important;
  width: 100%;
  top: 168px !important;
}
img {
  width: 100%;
  height: auto;
}
@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: calc(100% - 120px) !important;
  }
}
@media (min-width: 1700px) {
  .container_md {
    max-width: 1600px !important;
  }
}
