@import "../../variables";

body {
  background: @main-color-cl-07;
}


.navbar {
  //position: absolute;
  z-index: 4;
  width: 100%;
  pointer-events: none;
  .container {
    min-height: 88px;
    a, button {
      pointer-events: all;
    }
  }

  @media (min-width: 1400px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
      max-width: calc(100% - 120px);
    }
  }
}
