.pyFunc(@v) {
  padding-top: @v !important;
  padding-bottom: @v !important;
}
.pxFunc(@v) {
  padding-left: @v !important;
  padding-right: @v !important;
}

.mx(@v) {
  margin-left: @v;
  margin-right: @v;
}

.mb(@v) { margin-bottom:  @v !important; }
