.container {
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(4px);
  border-radius: 20px;
  padding: 5px 10px;
}
.tooltip {
  background: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1) !important;
  border-radius: 10px !important;
  border: none !important;
}
.tooltip span {
  color: #00205B;
}
.tooltip div:first-child:before {
  border: none;
}
.button_container {
  padding: 0 8px 7px 8px;
}
.button_container .btn_primary {
  background: #F6F6F9 !important;
  border-radius: 8px !important;
  height: 48px;
  color: #666687 !important;
  width: 100%;
  border: none !important;
  text-transform: none;
  padding: 15px 20px;
}
.button_container .btn_primary:hover,
.button_container .btn_primary:active,
.button_container .btn_primary:focus {
  box-shadow: none !important;
}
.language {
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin: 0 22px 0 30px;
}
.language img {
  margin-right: 5px;
  pointer-events: none;
}
.language span {
  color: #666687;
  text-transform: uppercase;
}
.text_container {
  padding: 28px 43px;
  color: #00205B;
}
.popover_container {
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1) !important;
}
.link_container {
  padding: 11px 8px 10px 8px;
  display: flex;
  flex-direction: column;
}
.link_container .link {
  color: #212134 !important;
  font-size: 14px;
  background: transparent !important;
  border-radius: 8px !important;
  height: 34px;
  width: 100%;
  border: none !important;
  text-transform: none;
}
.link_container .link:not(.link_container .link:last-child) {
  margin-bottom: 10px;
}
.link_container .link:hover,
.link_container .link:active,
.link_container .link:focus {
  box-shadow: none !important;
}
.avatar {
  width: 48px;
  height: 48px;
  transition: all 0.5s ease;
  border: 1px solid #C0C0CF;
}
