@import "../variables";

.map_wrap {
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  .marker {
    cursor: pointer;
    text-align: center;
    pointer-events: none;
    > * {
      pointer-events: auto;
    }
    .marker_image {
      max-height: 50px;
      width: auto;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
      &:hover {
        opacity: .8;
      }
    }
    p {
      color: @main-color-cl-01;
      position: absolute;
      bottom: 50px;
      width: max-content;
      left: 50%;
      transform: translate(-50%, 0);
      font-family: @main-font-family;
    }
    button {
      position: absolute;
      bottom: 54px;
      left: 50%;
      transform: translate(-50%, 0);
      text-align: initial;
      font-family: @main-font-family !important;
    }
  }
  .clusterMarker {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: @main-color-cl-01;
    color: @main-color-cl-08;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    cursor: pointer;

    .clusterPoint {
      font-size: @font-span;
      margin: 0 auto;
      font-weight: 500;
    }
  }
  .navigation_control {
    top: 180px;
    display: flex;
    flex-direction: column;
    align-items:center;
    position: relative;
    margin: 0 auto;
    @media @min992 {
      max-width: calc(960px - 50px - 20px);
    } @media (min-width: 1200px) {
      max-width: calc(1140px - 50px - 20px);
    } @media (min-width: 1400px) {
      max-width: calc(100vw - 120px - 50px - 20px);
    }

    .rotation_control {
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      border: 2px solid @main-color-cl-40;
      border-top-width: 1px;
      > * {
        border: none;
      }
    }

    .zoom_control {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      border: 2px solid @main-color-cl-40;
      border-bottom: none;
      inset: 10px 0 auto auto !important;
      > * {
        border-color: @main-color-cl-40;
      }
    }
  }
}
