.container {
  z-index: 3;
  width: 100%;
  margin-top: auto;
  background: #F6F6F9 !important;
}
.container .card {
  background: #F6F6F9;
  border: none;
  box-shadow: 2px 4px 20px #DCDCE4;
  text-align: left;
}
.container .card .card_header {
  left: 50%;
  width: 75px;
  height: 40px;
  margin: 0 auto;
  transform: translate(0, -90%);
  background: inherit;
  border: none;
  border-radius: 10px;
  padding: 0;
}
.container .card .card_header button {
  background: transparent;
  border: none;
  transform: rotate(-180deg);
  transition: all 0.2s ease;
}
.container .card .card_header button img {
  width: 17px;
}
.container .card .card_header button.isOpen {
  transform: rotate(0);
}
.container .card .card_body {
  height: 380px;
  display: flex;
  flex-direction: column;
  padding: 0 40px;
}
@media (min-width: 1400px) {
  .container_md {
    max-width: 1600px !important;
  }
}
