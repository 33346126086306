@import "../../variables";

.label {
  color: @main-color-primary;
  size: 18px;
}

.modal_card {
  border: 1px solid @main-color-primary !important;
  height: 52px !important;
  min-width: 155px;
}

.error_text {
  color: @red;
  position: relative;
  display: block;
  text-align: left;
  margin: -35px 0 0;
}

.add_form_wrap {
  height: calc(100vh - 300px);
  padding-bottom: 150px;
  .add_form {
    background: @main-color-form-bg;
    box-shadow: @main-form-shadow;
    border-radius: @main-form-br;
    padding: 80px;
  }
}
