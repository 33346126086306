.wrap_card {
  background: #FFFFFF;
  border-radius: 10px;
  color: #00205B;
  transition: opacity 0.5s ease;
  text-align: start;
  width: auto;
  height: auto;
  box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
  padding: 0;
  overflow: hidden;
  /* -------------- CARD -------------- */
}
.wrap_card .card {
  width: 655px;
  height: 562px;
}
.wrap_card .card .btn_close {
  position: absolute;
  right: 25px;
  width: 34px;
  height: 34px;
}
.wrap_card .card .h1 {
  font-size: 18px;
  font-weight: 500;
  padding: 15px 0 35px 25px;
}
.wrap_card .card .h1 button {
  top: 5px;
}
.wrap_card .card .h2 {
  font-size: 24px;
  text-align: center;
  text-transform: uppercase;
  margin-top: -20px;
}
.wrap_card .card .oeffnungswert {
  text-align: center;
  font-weight: 300;
  margin-top: -15px;
  font-size: 64px !important;
}
.wrap_card .card .oeffnungswert span.temperature,
.wrap_card .card .oeffnungswert span.range_value {
  text-align: center;
  font-weight: 300;
  font-size: 64px !important;
  margin: -15px 15px 0;
}
.wrap_card .card .oeffnungswert .prozent {
  font-size: 28px;
  color: #8E8EA9;
  font-weight: 500;
  position: absolute;
  margin: 18px 0 0 -8px;
}
.wrap_card .card .oeffnungswert .prozent.to_bottom {
  position: relative;
  margin: 0 0 0 -8px;
}
.wrap_card .card .range_slider {
  text-align: center;
  height: 256px;
  /*    border:1px solid #000;*/
}
.wrap_card .card .input_range {
  height: 239px;
}
.wrap_card .card .vorlauf_container {
  position: absolute;
  width: 200px;
  height: 150px;
  top: 300px;
  left: 60px;
  text-align: center;
  /*    border:1px solid #000;*/
  text-transform: uppercase;
}
.wrap_card .card .temp_rising {
  margin-right: 0;
  margin-top: -10px;
}
.wrap_card .card .ruecklauf_container {
  position: absolute;
  width: 200px;
  height: 150px;
  top: 300px;
  right: 60px;
  text-align: center;
  /*    border:1px solid #000;*/
  text-transform: uppercase;
}
.wrap_card .card .divider {
  padding: 0 25px 0 25px;
}
.wrap_card .card .bottomrow p {
  font-size: 24px;
  text-align: center;
}
.wrap_card .card .bottomrow p .delta_value,
.wrap_card .card .bottomrow p .volumenstrom_value {
  font-size: 24px !important;
  text-align: center;
}
.wrap_card .card .bottomrow .einheit {
  font-size: 18px;
  color: #8E8EA9;
  margin-left: 8px;
}
.wrap_card .card .bottomrow .einheitenbeschreibung {
  font-size: 16px;
  color: #8E8EA9;
  text-transform: uppercase;
  margin-top: -15px;
}
.wrap_card hr.solid {
  border-top: 1px solid #bbb;
}
.wrap_card .streetbar {
  background: #FFFFFF;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
  margin-bottom: 100px;
}
.wrap_card .streetbar .strasse {
  font-size: 36px;
  margin-top: 54px;
}
.wrap_card .streetbar .ort {
  font-size: 36px;
  font-weight: 300;
  margin-top: -15px;
}
.wrap_card .liegenschaft_thumbnail {
  padding: 16px;
}
.wrap_card .bottomrow .temp_arrow_decreasing {
  margin-right: 3px;
  width: 8px;
}
.wrap_card .arrow_red_increasing {
  width: 10px;
  height: 33px;
  display: inline-flex;
  vertical-align: 5px;
  position: absolute;
}
.wrap_card .arrow_red_increasing > div {
  overflow: inherit !important;
}
.wrap_card input[type=range] {
  width: 37%;
  height: 6px !important;
  transform: translate(0, 90px) rotate(-90deg);
}
.wrap_card .input_range {
  -webkit-appearance: none;
  height: 100%;
  width: 6px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  background-image: linear-gradient(#00205B, #00205B);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.wrap_card .input_range:hover {
  opacity: 1;
}
.wrap_card .input_range:focus {
  outline: none;
}
.wrap_card .input_range::-webkit-slider-thumb {
  border: 2px solid #00205B;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 50%;
  cursor: pointer;
  width: 20px;
  height: 20px;
  left: 951px;
  top: 386px;
  background: #F6F6F9;
  box-shadow: -14.04057e 3.2px 6.4px rgba(49, 49, 50, 0.17), inset -22.22516e -0.853333px 0.853333px #E8E8EB, inset -11.77484e 0.853333px 0.853333px #FFFFFF;
}
.wrap_card .input_range::-moz-range-thumb {
  border: 2px solid #00205B;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 50%;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background: #F6F6F9;
  box-shadow: -14.04057e 3.2px 6.4px rgba(49, 49, 50, 0.17), inset -22.22516e -0.853333px 0.853333px #E8E8EB, inset -11.77484e 0.853333px 0.853333px #FFFFFF;
}
