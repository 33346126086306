@keyframes _show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes _hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes _fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes pulse {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(0.7);
    opacity: 0;
  }
}
.container {
  width: 160px;
  height: 160px;
  color: #00205B;
  position: relative;
  margin: auto;
}
.container .svg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  transition: opacity 3s;
}
.container .temperature {
  z-index: 4;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #666687;
}
.container .temperature .temperature_box {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.container .temperature .temperature_box span:first-child {
  color: #00205B;
  font-size: 32px;
  line-height: 100%;
}
.container .temperature .temperature_box span:last-child {
  color: #A5A5BA;
  font-size: 18px;
  margin: 0;
  padding: 0;
}
.container .temperature .time_box {
  z-index: 4;
  font-size: 12px;
  margin: 10px 0 -30px;
}
.container ~ span {
  width: calc(100% + 100px);
}
.sub_box {
  margin-top: 13px;
  min-height: 63px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.sub_box .subtitle {
  width: 100%;
  padding: 0 2px;
  font-size: 14px;
  line-height: 21px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.svg_off {
  animation: _hide 3s;
  opacity: 0;
}
.svg_on {
  animation: _show 3s;
  opacity: 1;
}
