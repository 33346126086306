.tabs {
  border-bottom: none !important;
}
.tabs > li {
  display: none;
}
.tabs > ul {
  display: none !important;
}
.filters_container {
  margin-bottom: 24px;
}
.nav-tabs {
  display: none;
}
.title {
  color: #00205B;
}
