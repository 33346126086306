@import "../variables";

.table_wrap {
  background: white;
  box-shadow: @main-form-shadow !important;
  border-radius: @main-form-br !important;
  padding: 16px 24px 24px;
  margin-top: 0 !important;
}
.table {
  * {
    font-family: @main-font-family !important;
  }
  thead th {
    font-size: 12px;
    line-height: 26px;
    color: @main-color-cl-01;
    padding-bottom: 22px;
    border: none;
  }
  tbody tr {
    td {
      height: 40px;
      font-size: 16px;
      line-height: 23px;
      color: @main-color-cl-10;
    }
    & td, &:last-child td {
      border-bottom: 1px solid @main-color-cl-18;
    }
  }
}