@import "../../variables";

.swiperContainer {
    visibility: visible;
    position: absolute !important;
    display: flex;
    flex-direction: column;
    z-index: 9;
    bottom: 0;
    width: calc(100% - 72px) !important;
    height: auto;
    margin: 36px;
    pointer-events: none;
    * {
        pointer-events: auto;
    }

    .btnContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 15px;
        pointer-events: none;
        * {
            pointer-events: auto;
        }

        .selectBtn {
            width: 90px;
            height: 30px;
            text-align: center;
            border-radius: 40px;
            border: none;
            font-weight: 600;
            font-size: @font-span;
            transition: @transition05;
        }
        .selectBtn:hover {
            background: @main-color-cl-01;
            color: @main-color-cl-08;
        }
        .selectBtn:first-child {
            margin-right: 14px;
        }
        .activeBtn {
            background: @main-color-cl-01;
            color: @main-color-cl-08;
        }
        .unActiveBtn {
            background: @main-color-cl-08;
            color: @main-color-cl-01;
        }
        @media (min-width: 2560px) {
            margin-bottom: 25px;
            .selectBtn {
                font-size: 21px;
                width: 150px;
                height: 50px;
            }
        }
    }

    .cardContainer {
        box-sizing: border-box;
        justify-content: space-between;
        padding: 8px;
        width: 208px;
        height: 148px;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        background-color: @main-color-cl-08;
        cursor: pointer;
        transition: @transition02;
        border: 2px solid transparent;

        h3 {
            color: @main-color-cl-01;
            font-weight: 600;
            font-size: @font-little-span !important;
        }
        .image360 {
            width: 188px;
            border-radius: 8px;
        }
        &.active {
            border-color: @main-color-cl-02;
        }
        &.unActive {
            opacity: 0.6;
        }
        &:hover {
            opacity: 1;
        }
        @media (min-width: 2560px) {
            padding: 15px;
            width: 300px;
            height: 200px;

            h3 {
                font-size: @font-p !important;
            }
            .image360 {
                width: 270px;
            }
        }
    }
}

.hiddenContainer {
    visibility: hidden;
}

