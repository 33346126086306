.accordion {
  min-height: 340px;
  position: relative;
  width: max-content;
  margin-left: auto;
  pointer-events: none;
}
.accordion > * {
  pointer-events: auto;
}
.accordion .accordion_btn button {
  background: transparent;
  border: none !important;
  box-shadow: none !important;
}
.accordion .accordion_btn button h3 {
  font-weight: 500;
  margin-left: 8px;
}
.accordion .accordion_btn button:after {
  background: url("../../../icons/arrow.svg") no-repeat;
  background-position: center;
}
.accordion .accordion_body {
  padding-top: 0;
}
.accordion .filter_container {
  width: 260px;
  background: white;
  overflow: hidden;
  position: relative;
  border-radius: 10px !important;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  pointer-events: auto;
  z-index: 2;
}
.accordion .filter_container .list_group_item {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom-color: #EAEAEF;
}
