@import "../../variables";

.temperature_wrap {
  display: flex;
  span:first-child {
    font-size: 40px;
    font-weight: 400;
    line-height: normal;
  }
  span:last-child {
    color: @main-color-cl-14;
    font-size: 24px;
    display: block;
    margin-bottom: auto;
    margin-top: 1px;
    margin-left: 1px;
  }
  & ~ span {
    color: @main-color-cl-14;
  }
}
