@import "../../variables.less";

.cropper-box {
    .cropper-container {
        background-color: @main-color-cl-07;
        width: 60vw !important;
        height: 70vh !important;
        .cropper-view-box {
            border-radius: 50%;
        }
    }
    .btn-primary {
        width: 150px;
    }
}