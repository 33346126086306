@import "../../variables";

.back_btn {
  margin-top: 18px !important;
}

.tabs {
  margin: 140px 0 -60px 0;
  li > button {
    font-size: @font-h7 !important;
    background: transparent !important;
  }
}

.box {
  margin-bottom: -65px;
  .hr {
    margin: 70px 0 70px;
  }
  h2 {
    font-weight: 400;
    margin-bottom: 0;
    line-height: inherit;
  }
}