@import "../variables";

.wrap {
  width: 100%;
  top: 168px !important;
}

.container_md {
  margin-top: 69px;
}

@media (min-width: 1400px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: calc(100% - 120px) !important;
  }
}

@media (min-width: 1700px) {
  .container_md {
    max-width: 1600px !important;
  }
}

.page_container {
  min-height: 20vh;
}