@import "../../variables";

.container {
  &:not(.just_image) {
    margin: 40px 30px 8px 8px;
    display: grid;
    grid-template-columns: .8fr 1fr;
    grid-template-rows: 1fr;
    gap: 0 56px;
  }
  @media @max993 {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 40px 0;
  }
  h5 {
    font-weight: 500;
    font-size: 32px !important;
    margin: 16px 0 32px 0;
    width: max-content;
  }
  .img_wrap {
    background: @main-color-cl-16;
    border-radius: 10px;
    overflow: hidden;
    min-height: 334px;
    margin-bottom: auto;
    //width: fit-content;
    min-width: 450px;
    max-width: 400px;
    width: 450px;
    height: 450px;
    display: flex;
    > * {
      width: 100%;
    }
  }
  &.just_image {
    margin: 40px 8px 8px;
    .img_wrap {
      width: 100%;
      max-width: none;
      min-height: 63vh;
      @media (min-height: 800px) {
        min-width: 700px;
        //min-height: 700px;
      }
      @media @min1199 and (min-height: 1000px) {
        min-width: 930px;
        //min-height: 930px;
      }
    }
  }
  .info {
    display: grid;
    grid-template-columns: .8fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    gap: 0 40px;
    > * {
      font-size: @font-p;
      width: max-content;
      margin-bottom: 20px;
      font-weight: 400;
      color: @main-color-cl-01;
      &.color_red {
        color: @main-color-cl-21;
      }
      &:nth-child(2n - 1) {
        font-weight: 500;
      }
    }
  }
}
