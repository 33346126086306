@import "../../variables";

.container {
  position: absolute;
  display: flex;
  z-index: 1;
  .tracker_name {
    width: 10px;
    font-size: @font-small;
    padding: 0.25px 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: @main-color-cl-08;
    background: @main-color-cl-01;
    height: 100%;
  }
  &.status_good .tracker_name {
    background: @main-color-cl-01;
  }
  &.status_bad .tracker_name {
    background: @main-color-cl-21;
  }
  .tracker_temperature {
    //min-width: 32px;
    height: 24px;
    padding: 0 5px;
    background: rgba(0, 32, 91, 0.2);
    border: 1px solid #363E47;
    box-sizing: border-box;
    backdrop-filter: blur(4px);
    border-radius: 0 3px 3px 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: @main-color-cl-08;
    font-size: @font-span;
    span {
      font-size: 11px;
      line-height: 11px;
      margin-bottom: 4px;
    }
  }
}