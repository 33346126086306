@import "../variables";
@import "../global";

.lg-container {
  .lg-outer {
    border-radius: 8px;
  }
  &.lg-show.lg-show-in.lg-inline {
    position: relative;
    height: 100%;
    width: 100%;
    .lg-fullscreen {
      display: none;
    }
    .lg-backdrop {
      background: transparent;
    }
  }
  .lg-toolbar {
    opacity: 0;
    transition: opacity .5s;
  }
  .lg-content {
    top: 0 !important;
  }
  .lg-pager-outer {
    margin-bottom: 16px;
  }
  .lg-pager-cont {
    margin: 0 2px !important;
    & > span {
      box-shadow: none !important;
      width: 8px !important;
      height: 8px !important;
      background: @main-color-cl-19 !important;
    }
    &.lg-pager-active {
      & > span {
        background: @main-color-cl-01 !important;
      }
    }
  }
  .lg-next, .lg-prev {
    width: 40px;
    height: 40px;
    background: @main-color-cl-19;
    opacity: 0.85 !important;
    border-radius: 50%;
    border: none;
    transition: @transition02;
    &:hover {
      opacity: 1 !important;
    }
  }
  .lg-next:before, .lg-prev:after {
    content: '';
    width: 18px;
    height: 11px;
    display: block;
  }
  .lg-prev {
    left: 10px;
    &:after {
      background: url("../../icons/arrow.svg") no-repeat;
      transform: rotate(90deg);
      margin-right: 2px;
    }
  }
  .lg-next {
    right: 10px;
    &:before {
      background: url("../../icons/arrow.svg") no-repeat;
      transform: rotate(270deg);
      margin-left: 2px;
    }
  }
  .lg-pager-thumb-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 0;
    border: 5px solid white;
    img {
      width: 100%;
      height: auto;
      max-width: 100%;
      max-height: initial;
    }
  }
  .lg-img-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    .user-select-none;
    img {
      width: 100%;
      height: auto;
      max-height: initial;
      pointer-events: none;
    }
  }
  .lg-maximize {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @main-color-cl-01;
    border-radius: 8px;
    width: 36px;
    height: 36px;
    margin: 10px;
    &:after {
      content: '';
      background: url('../../icons/fullscreen.svg') no-repeat;

      display: block;
      width: 24px;
      height: 24px;
    }
  }
  &:not(&.lg-inline) {
    .lg-maximize {
      &:after {
        background: url('../../icons/smallscreen.svg') no-repeat 50% 50%;
      }
    }
    .lg-img-wrap {
      img {
        width: auto;
        height: auto;
      }
    }
  }
  &:hover {
    .lg-toolbar {
      opacity: 1;
      transition: opacity .2s;
    }
  }
}

.fullscreen {
  transform: inherit !important;
}