@import "../../variables";

.tabs {
  border-bottom: none !important;
  & > li {
    display: none;
  }
  & > ul {
    display: none !important;
  }
}

.filters_container {
  margin-bottom: 24px;
}

.nav-tabs {
  display: none;
}

.title {
  color: @main-color-cl-01;
}
