@import "../variables";

.calendar {
  & * {
    font-family: @main-font-family;
  }
  .arrow {
    border-radius: 50%;
    min-width: auto;
    color: @main-color-cl-16;
    transition: @transition02;
    opacity: 1;
    &:hover {
      background: transparent;
      color: @main-color-cl-01;
    }
    &[disabled] {
      opacity: .4;
    }
  }
  .date_box {
    color: @main-color-cl-01;
    font-size: @font-p;
    text-transform: capitalize;
    font-weight: 400;
    margin: 0 15px;
    &:hover {
      background: transparent;
    }
  }
}

.week {
  position: absolute !important;
  font-size: 0.75rem !important;
  left: 5px;
  width: 36px;
  height: 36px;
  margin: 2px;
  min-width: auto !important;
  border-radius: 50% !important;
}

#customPicker {
  background-color: #1976d2 !important;
  color: @main-color-cl-08 !important;
}