@import "../../variables";

.house_wrap {
  color: @main-color-cl-01;
  .house_list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    > div {
      margin: 0 40px 85px 0;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 0.1fr 2.1fr 0.75fr;
      gap: 0 0;
      grid-template-areas: "." "." ".";
      @media (max-width: 992px) {
        margin-right: auto;
        margin-left: auto;
      }
      @media (min-width: 993px) and (max-width: 1400px) {
        &:nth-child(n) {
          margin-left: auto;
        }
        &:nth-child(2n) {
          margin-left: 0;
          margin-right: auto;
        }
        &:nth-child(odd):last-child {
          margin-right: auto;
        }
      }
      @media (min-width: 1400px) and (max-width: 1699px) {
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
      @media (min-width: 1700px) {
        &:nth-child(4n) {
          margin-right: 0;
        }
      }
    }
  }

}
