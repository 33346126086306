@import "../variables";

.login_form_wrap {
  height: calc(100vh - 300px);
  padding-bottom: 150px;
  .login_form {
    background: @main-color-cl-08;
    box-shadow: @main-form-shadow;
    border-radius: @main-form-br;
    padding: 80px;
    h2 {
      color: @main-color-cl-11 !important;
      margin-bottom: 50px;
      font-weight: bold;
    }
    .label {
      color: @main-color-cl-11 !important;
      font-weight: bold;
    }
    .help_links {
      *:first-child {
        margin-right: 40px;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          height: 100%;
          width: 1px;
          background: @main-color-cl-01;
          top: 0;
          margin-left: 40px;
        }
      }
      a {
        color: @main-color-cl-11 !important;
        font-weight: bold;
      }
      *:last-child {
        margin-left: 40px;
      }
    }
    .error_text {
      color: @main-color-cl-21;
      position: relative;
      display: block;
      text-align: center;
      margin: -15px 0 20px;
    }
  }
}
