.container:not(.just_image) {
  margin: 40px 30px 8px 8px;
  display: grid;
  grid-template-columns: 0.8fr 1fr;
  grid-template-rows: 1fr;
  gap: 0 56px;
}
@media (max-width: 993px) {
  .container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 40px 0;
  }
}
.container h5 {
  font-weight: 500;
  font-size: 32px !important;
  margin: 16px 0 32px 0;
  width: max-content;
}
.container .img_wrap {
  background: #C0C0CF;
  border-radius: 10px;
  overflow: hidden;
  min-height: 334px;
  margin-bottom: auto;
  min-width: 450px;
  max-width: 400px;
  width: 450px;
  height: 450px;
  display: flex;
}
.container .img_wrap > * {
  width: 100%;
}
.container.just_image {
  margin: 40px 8px 8px;
}
.container.just_image .img_wrap {
  width: 100%;
  max-width: none;
  min-height: 63vh;
}
@media (min-height: 800px) {
  .container.just_image .img_wrap {
    min-width: 700px;
  }
}
@media (min-width: 1199px) and (min-height: 1000px) {
  .container.just_image .img_wrap {
    min-width: 930px;
  }
}
.container .info {
  display: grid;
  grid-template-columns: 0.8fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  gap: 0 40px;
}
.container .info > * {
  font-size: 18px;
  width: max-content;
  margin-bottom: 20px;
  font-weight: 400;
  color: #00205B;
}
.container .info > *.color_red {
  color: #D02B20;
}
.container .info > *:nth-child(2n - 1) {
  font-weight: 500;
}
