@import "../../variables.less";

.inputContainer {
    cursor: pointer;
    height: 25px;
    width: auto;
}

.calendarIcon {
    width: 24px;
    height: 24px;
}

.input {
    width: 105px;
    height: 25px;
    color: @main-color-cl-12;
    font-size: @font-p;
    background: none;
    border: none;
    outline: none;
}

.input:hover, .input:focus, .input:active {
    text-underline-offset: 1.5px;
    text-decoration-line: underline;
}