@import '../../variables';

.icon {
  display: flex;
  max-width: 326px;
  &:hover:after {
    margin-left: 2px;
    content: url(../../../icons/edit.svg);
  }
}

.fieldContainer {
  height: 68px;
  margin-bottom: 43px;
  .fieldTitle {
    color: @main-color-cl-01;
    font-weight: 500;
    text-transform: uppercase;
  }
  .input {
    @media not all and (min-resolution:.001dpcm) {
      @supports (-webkit-appearance:none) {
        padding-left: 1px;
      }
    }
    height: 25px;
    width: auto;
    max-width: 300px;
    color: @main-color-cl-12;
    font-size: @font-p;
    overflow: hidden;
    white-space: nowrap;
    &:not(&[readOnly]) {
      cursor: text;
    }
    &[contenteditable][placeholder]:empty:before {
      content: attr(placeholder);
      position: sticky;
      color: @main-color-cl-14;
      background-color: transparent;
    }
    &:hover,
    &:focus,
    &:active {
      text-underline-offset: 1.5px;
      text-decoration-line: underline;
      background: none;
      border: none;
      outline: none;
    }
    &:empty {
      &:hover {
        text-decoration-line: none !important;
      }
      &:focus {
        text-underline-offset: 1.5px;
        text-decoration-line: underline !important;
      }
    }
    &:before,
    &:after {
      content: none;
      border-bottom: none;
    }
  }
}