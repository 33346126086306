@import "functions";
@import "variables";


// ---- inputs ----
input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"] {
  background: @main-color-cl-09;
  box-sizing: border-box;
  border-radius: @input-br;
  border: 1px solid transparent;
  width: 400px;
  height: 52px;
  transition: @transition02;
  &:hover, &:active, &:focus {
    border-color: @main-color-cl-16;
    background: @main-color-cl-09;
    box-shadow: none;
  }
}

// ---- buttons ----
.btn-primary {
  background: @main-color-cl-01 !important;
  border-radius: @btn-br !important;
  height: 52px;
  color: @main-color-cl-08 !important;
  border: none !important;
  font-size: @font-span !important;
  text-transform: capitalize !important;
  &:hover {
    background-color: @main-color-cl-01-hover !important;
  }
  &:hover, &:active, &:focus {
    box-shadow: none !important;
  }
  &:disabled {
    cursor: no-drop;
    opacity: .5;
  }
}
.btn-secondary {
  background: @main-color-cl-19  !important;
  border-radius: @btn-br !important;
  height: 52px;
  color: @main-color-cl-13 !important;
  border: none !important;
  font-size: @font-span !important;
  text-transform: capitalize !important;
  &:hover {
    background-color: @main-color-cl-18 !important;
  }
  &:hover, &:active, &:focus {
    box-shadow: none !important;
  }
  &:disabled {
    cursor: no-drop;
    opacity: .5;
  }
}
.btn {
  &-b { height: 52px }
  &-m { height: 40px }
  &-s { height: 30px }
}

.btn-modal-primary {
  width: 125px;
  height: 40px;
}

.btn-modal-secondary {
  width: 125px;
  height: 40px;
  border: 1px solid #666687 !important;
  background-color: transparent;
}

.input-modal {
  width: 100% !important;
}

// ---- links ----
a {
  color: @main-color-cl-11 !important;
  text-decoration: none !important;
}
// ---- flex ----
.flex {
  display: flex;
  &-imp {display: flex !important}
}
.flex-top-center {
  .flex();
  align-items: center;
}
.flex-top-center {
  .flex-top-center();
  &-tablet {
    @media @max993 {
      .flex-top-center() !important;
    }
  }
}

.flex-left-center {
  .flex();
  justify-content: center;
}
.flex-left-center {
  .flex-left-center();
  &-tablet {
    @media @max993 {
      .flex-left-center() !important;
    }
  }
  &-desktop {
    @media @min992 {
      .flex-left-center() !important;
    }
  }
}

.flex-center {
  .flex-top-center();
  .flex-left-center();
}

.flex-column {
  .flex();
  flex-direction: column;
}
.flex-column {
  .flex();
  flex-direction: column;
  @media @max993 {
    align-items: center;
  }
  &-tablet {
    @media @max993 {
      .flex-column() !important;
    }
  }
  &-mobile {
    @media @max560 {
      .flex-column() !important;
    }
  }
}
.flex-row {
  .flex();
  flex-direction: row;
  &-d {
    @media @min992 {
      .flex();
      align-items: center;
      max-width: 990px;
      & > * {
        .mx(5px)
      }
    }
  }
}
.flex-between {
  .flex();
  justify-content: space-between;
  &-desktop {
    @media @min992 {
      .flex();
      justify-content: space-between;
    }
  }
}
.flex-evenly {
  .flex();
  justify-content: space-evenly;
}
.flex-around {
  .flex();
  justify-content: space-around;
}
.flex-end {
  .flex();
  justify-content: flex-end;
}
.display {
  &-none {display: none}
}

// ---- sizes ----
.h {
  &-500 {height: 500px}
}
.min-h {
  &-200 { min-height: 200px}
}
.py {
  &-40 {.pyFunc(40px)}
  &-60 {.pyFunc(60px)}
}
.px {
  &-7 {.pxFunc(7px)}
  &-20 {.pxFunc(20px)}
  &-25 {.pxFunc(25px)}
}
.pt {
  &-15 {padding-top: 15px}
  &-50 {padding-top: 50px}
  &-70 {padding-top: 70px}
}
.pb {
  &-40 {padding-bottom: 40px}
}
.mb {
  &-20 {.mb(20px)}
  &-24 {.mb(24px)}
  &-35 {.mb(35px)}
  &-40 {.mb(40px)}
  &-50 {.mb(50px)}
  &-70 {.mb(70px)}
  &-120 {.mb(120px)}
  &-140 {.mb(140px)}
}

// ---- fonts ----
.fw {
  &-normal { font-weight: normal; }
  &-bold { font-weight: 600; }
}

.text {
  &-keft { text-align: left}
  &-center { text-align: center}
  &-right { text-align: right}
  &-initial { text-align: initial}
}

// ---- cursor ----
.cursor {
  &-pointer { cursor: pointer }
}
.pointer-event {
  &-none { pointer-events: none }
}

.user-select-none {
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}
.user-select-auto {
  -moz-user-select: auto;
  -khtml-user-select: auto;
  user-select: auto;
}
.user-select {
  &-none { .user-select-none(); }
  &-auto { .user-select-auto(); }
}

// ---- colors ----
.color {
  &-red { color: @main-color-cl-21; }
  &-main-color-primary { color: @main-color-cl-01 }
  &-secondary-color { color: @main-color-cl-11 }
}
.bg-color {
  &-main-color-form-bg { background-color: @main-color-cl-08 }
}

// ---- containers ----
.b-shadow {
  box-shadow: @main-form-shadow;
}
.container-box {
  border-radius: @main-form-br;
  width: 100%;
}
.container-box-shadow {
  .container-box();
  .b-shadow();
}
.container-header {
  margin: 0 0 -10px;
  font-weight: 500;
  color: @main-color-cl-01;
}

// ---- list ----
.listItem {
  border-bottom: 1px solid @main-color-cl-18;
  min-height: 40px;
  & > div[role="button"] {
    min-height: inherit;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  span {
    font-size: @font-span !important;
    text-transform: capitalize;
  }
}
// ---- opacity ----
.opacity {
  &-0 { opacity: 0 !important;}
  &-1 { opacity: 1 !important;}
}