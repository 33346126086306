@import "../../variables";
@import "../../functions";


.selector {
  color: @main-color-cl-01 !important;
  background: transparent;
  font-size: @font-span !important;
  border: 1px solid @main-color-cl-13;
  box-sizing: border-box !important;
  border-radius: 8px !important;
  transition: @transition02;
  //padding: 5px 20px;
  padding: 0;
  height: auto;
  font-family: @main-font-family;
  & > svg {
    color: @main-color-cl-01;
    margin-right: 10px;
    transition: @transition02;
    cursor: pointer;
    position: absolute;
    right: 0;
    &.select_is_open {
      transform: rotate(180deg);
    }
  }
  &.active {
    color: @main-color-cl-08 !important;
    background: @main-color-cl-01;
    border-color: @main-color-cl-01;
    & > svg {
      display: none;
      color: @main-color-cl-08;
    }
  }
  fieldset {
    border: none;
  }
  & > div {
    padding: 5px 40px 5px 20px !important;
    span.count {
      display: none;
    }
  }
}

.MuiPaper-root {
  margin-top: 13px;
  border-radius: 8px !important;
  ul {
    padding: 0;
    li {
      //min-width: 200px;
      min-height: 40px;
      color: @main-color-cl-01;
      display: block;
      line-height: 180%;
      &[aria-selected='true'] {
        background: @main-color-cl-19;
      }
      &:hover {
        background: @main-color-cl-17 !important;
      }
      .count {
        color: @main-color-cl-13;
      }
      .selector_remove_btn {
        display: none;
      }
    }
  }
}
.selector_remove_btn {
  position: absolute;
  top: 0;
  right: 8px;
  padding: 0 10px;
  width: 34px;
  color: @main-color-cl-08;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  transition: @transition02;
  > * {
    width: 18px !important;
    height: 18px !important;
  }
  &:hover {
    opacity: .9;
    color: @main-color-cl-08;
  }
}
