@media (min-width: 992px) {
  .wrap {
    margin-top: calc(85vw - 580px - 166px - 50px - 24px);
  }
}
@media (min-width: 1200px) {
  .wrap {
    margin-top: calc(80vw - 580px - 166px - 50px - 24px);
  }
}
@media (min-width: 1440px) {
  .wrap {
    margin-top: calc(75vw - 580px - 166px - 50px - 24px);
  }
}
@media (min-width: 1600px) {
  .wrap {
    margin-top: calc(1150px - 580px - 166px - 50px - 24px);
  }
}
@media (min-width: 1900px) {
  .wrap {
    margin-top: calc(1150px - 580px - 166px - 50px - 24px);
  }
}
@media (min-width: 2300px) {
  .wrap {
    margin-top: calc(1150px - 580px - 166px - 50px - 24px);
  }
}
@media (min-width: 3000px) {
  .wrap {
    margin-top: calc(1200px - 580px - 166px - 50px - 24px);
  }
}
@media (min-width: 3300px) {
  .wrap {
    margin-top: calc(1250px - 580px - 166px - 50px - 24px);
  }
}
@media (min-width: 3500px) {
  .wrap {
    margin-top: calc(1300px - 580px - 166px - 50px - 24px);
  }
}
@media (min-width: 3800px) {
  .wrap {
    margin-top: calc(1350px - 580px - 166px - 50px - 24px);
  }
}
@media (min-width: 4200px) {
  .wrap {
    margin-top: calc(1400px - 580px - 166px - 50px - 24px);
  }
}
.wrap .briefInfo {
  position: relative;
  margin-bottom: 30px;
  color: #00205B;
  transition: all 0.2s ease;
  z-index: 2;
  pointer-events: none;
  opacity: 0;
}
.wrap .briefInfo.hidden {
  opacity: 0;
}
.wrap .briefInfo span,
.wrap .briefInfo a {
  width: auto;
  margin-right: 10px;
}
.wrap .briefInfo span:not(:first-child),
.wrap .briefInfo a:not(:first-child) {
  margin-left: 10px;
}
.wrap .briefInfo span.bold,
.wrap .briefInfo a.bold {
  text-decoration: underline !important;
  font-weight: bold;
  color: #00205B !important;
}
.wrap .container {
  transition: all 0.2s ease;
  position: relative;
  background: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  min-height: 220px;
  margin-bottom: 110px;
  padding: 16px;
  color: #00205B;
  z-index: 2;
}
.wrap .container .image_wrap {
  width: 275px;
  max-height: 190px;
  overflow: hidden;
  padding-left: 0;
}
.wrap .container .image_wrap .lazy_load_image {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 251px;
  height: 188px;
  overflow: hidden;
  border-radius: 8px;
}
.wrap .container .image_wrap .lazy_load_image img {
  margin: auto 0;
  height: 100%;
  width: auto;
}
.wrap .title {
  font-weight: 500;
}
