.box {
  padding: 24px 40px;
}
.box h2 {
  font-weight: 300 !important;
}
.box .title {
  font-weight: 500 !important;
}
.box button,
.box div.disabled_btn {
  margin-right: -40px;
  margin-top: -24px;
  height: 47px;
  width: 49px;
  min-width: auto;
}
.box button:hover,
.box div.disabled_btn:hover {
  background: transparent;
}
.box button img,
.box div.disabled_btn img {
  width: 24px;
  height: 24px;
}
