.btnWrapper {
  position: absolute !important;
  top: 145px;
  left: 80px;
}
@media (max-width: 1200px) {
  .btnWrapper {
    top: 95px;
    left: 40px;
  }
}
.logo {
  position: absolute;
  top: 70px;
  left: 80px;
  width: 104px;
  height: 45px;
  z-index: 3;
}
@media (max-width: 1200px) {
  .logo {
    top: 30px;
    left: 40px;
  }
}
.btnSmallScreen {
  position: absolute;
  top: 80px;
  right: 80px;
  background-color: #00205B !important;
  border-radius: 8px;
  border: none;
  width: 40px;
  height: 40px;
  background: url("../../../icons/smallscreen.svg") no-repeat 50% 50%;
  background-size: 30px 30px;
  z-index: 9;
}
@media (max-width: 1200px) {
  .btnSmallScreen {
    top: 40px;
    right: 40px;
  }
}
.btnFullScreen {
  position: absolute;
  background-color: #00205B !important;
  border-radius: 8px;
  z-index: 9;
  border: none;
  top: 8px;
  right: 8px;
  width: 36px;
  height: 36px;
  background: url("../../../icons/fullscreen.svg") no-repeat 50% 50%;
}
.btn360 {
  position: absolute;
  left: 50%;
  top: calc(100% - 8px);
  transform: translate(-50%, -100%);
  background: #00205B;
  color: #FFFFFF;
  font-size: 16px;
  border-radius: 8px;
  padding: 5px 20px;
  cursor: pointer;
  z-index: 9;
}
