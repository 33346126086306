.filters_wrap .iconFilters {
  width: 20px;
  height: 20px;
}
.filters_wrap > * {
  margin-left: 10px !important;
  margin-right: 10px !important;
  min-width: auto !important;
}
.filters_wrap > *:first-child {
  margin-left: 0 !important;
}
.filters_wrap > *:last-child {
  margin-right: 0 !important;
}
.filters_wrap .filter_btn_wrap {
  transition: all 0.5s ease;
  overflow: hidden;
}
.filters_wrap .filter_btn_wrap.warning_filter {
  width: 125px;
}
.filters_wrap .filter_btn_wrap.hide_filter {
  width: 0;
  margin: 0 !important;
  opacity: 0;
  pointer-events: none;
}
.filters_wrap .filter_btn {
  color: #666687;
  background: transparent;
  font-size: 16px;
  border: 1px solid #666687;
  box-sizing: border-box;
  border-radius: 40px;
  transition: all 0.2s ease;
  padding: 5px 20px;
  height: auto;
  text-decoration: none;
}
.filters_wrap .filter_btn:hover,
.filters_wrap .filter_btn:focus-within {
  color: #00205B;
  background: transparent;
  border-color: #00205B;
}
.filters_wrap .filter_btn.active_btn {
  color: #FFFFFF;
  background: #00205B;
  border-color: #00205B;
}
.filters_wrap .reset_btn {
  color: #00205B;
  transition: all 0.2s ease;
  min-width: 170px;
}
.filters_wrap .reset_btn.hide {
  opacity: 0;
  pointer-events: none;
}
.filters_wrap .clearIcon {
  display: none;
}
