@import "../variables";

.box {
  padding: 24px;
  border-radius: 10px;
  outline: none;
  width: auto !important;
  max-width: 90vw;
  overflow: auto;
  .box_header {
    h3 {
      margin-top: -10px;
      font-size: @font-h7 !important;
      font-family: @main-font-family;
    }
    .cancel_btn {
      position: absolute;
      color: @main-color-cl-01;
      right: 0;
      top: 0;
      padding: 10px;
      svg {    width: 37px;
        height: 37px;
      }
    }
    margin-bottom: 15px;
  }
  &.secondary_box {
    padding: 60px 65px 40px;
    .box_header {
      h5 {
        font-size: @font-h5 !important;
        font-family: @main-font-family;
      }
      margin-bottom: 40px;
    }
  }
}