@import "../../variables";

.tables_wrap {
  display: grid;
  gap: 0 40px;
  @media @max993 {
    grid-template-columns: 1fr;
  }
  @media @min992 {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
  & > * {
    height: max-content;
  }
}

.error_status {
  color: @main-color-cl-21;
}