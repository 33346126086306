.map_wrap {
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
}
.map_wrap .marker {
  cursor: pointer;
  text-align: center;
  pointer-events: none;
}
.map_wrap .marker > * {
  pointer-events: auto;
}
.map_wrap .marker .marker_image {
  max-height: 50px;
  width: auto;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}
.map_wrap .marker .marker_image:hover {
  opacity: 0.8;
}
.map_wrap .marker p {
  color: #00205B;
  position: absolute;
  bottom: 50px;
  width: max-content;
  left: 50%;
  transform: translate(-50%, 0);
  font-family: 'Ubuntu', sans-serif;
}
.map_wrap .marker button {
  position: absolute;
  bottom: 54px;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: initial;
  font-family: 'Ubuntu', sans-serif !important;
}
.map_wrap .clusterMarker {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #00205B;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}
.map_wrap .clusterMarker .clusterPoint {
  font-size: 16px;
  margin: 0 auto;
  font-weight: 500;
}
.map_wrap .navigation_control {
  top: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 0 auto;
}
@media (min-width: 992px) {
  .map_wrap .navigation_control {
    max-width: calc(960px - 50px - 20px);
  }
}
@media (min-width: 1200px) {
  .map_wrap .navigation_control {
    max-width: calc(1140px - 50px - 20px);
  }
}
@media (min-width: 1400px) {
  .map_wrap .navigation_control {
    max-width: calc(100vw - 120px - 50px - 20px);
  }
}
.map_wrap .navigation_control .rotation_control {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border: 2px solid #E5E5E7;
  border-top-width: 1px;
}
.map_wrap .navigation_control .rotation_control > * {
  border: none;
}
.map_wrap .navigation_control .zoom_control {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border: 2px solid #E5E5E7;
  border-bottom: none;
  inset: 10px 0 auto auto !important;
}
.map_wrap .navigation_control .zoom_control > * {
  border-color: #E5E5E7;
}
