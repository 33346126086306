.house_wrap {
  color: #00205B;
}
.house_wrap .house_list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.house_wrap .house_list > div {
  margin: 0 40px 85px 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.1fr 2.1fr 0.75fr;
  gap: 0 0;
  grid-template-areas: "." "." ".";
}
@media (max-width: 992px) {
  .house_wrap .house_list > div {
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 993px) and (max-width: 1400px) {
  .house_wrap .house_list > div:nth-child(n) {
    margin-left: auto;
  }
  .house_wrap .house_list > div:nth-child(2n) {
    margin-left: 0;
    margin-right: auto;
  }
  .house_wrap .house_list > div:nth-child(odd):last-child {
    margin-right: auto;
  }
}
@media (min-width: 1400px) and (max-width: 1699px) {
  .house_wrap .house_list > div:nth-child(3n) {
    margin-right: 0;
  }
}
@media (min-width: 1700px) {
  .house_wrap .house_list > div:nth-child(4n) {
    margin-right: 0;
  }
}
