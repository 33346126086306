.calendar * {
  font-family: 'Ubuntu', sans-serif;
}
.calendar .arrow {
  border-radius: 50%;
  min-width: auto;
  color: #C0C0CF;
  transition: all 0.2s ease;
  opacity: 1;
}
.calendar .arrow:hover {
  background: transparent;
  color: #00205B;
}
.calendar .arrow[disabled] {
  opacity: 0.4;
}
.calendar .date_box {
  color: #00205B;
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 400;
  margin: 0 15px;
}
.calendar .date_box:hover {
  background: transparent;
}
.week {
  position: absolute !important;
  font-size: 0.75rem !important;
  left: 5px;
  width: 36px;
  height: 36px;
  margin: 2px;
  min-width: auto !important;
  border-radius: 50% !important;
}
#customPicker {
  background-color: #1976d2 !important;
  color: #FFFFFF !important;
}
