@import "../../variables";
@import "../../animations";

#textInfo .hotspot {
  .in {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: rgba(255, 255, 255, .8);
    border-radius: 50%;
    transition: transform ease-out 0.1s, background 0.2s;
  }
  .out {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 5px solid rgba(255, 255, 255, .8);
    transition: transform ease-out 0.1s, border 0.2s;
    box-sizing: border-box;
    &:after {
      top: 0;
      left: 0;
      padding: 0;
      z-index: -1;
      box-shadow: 0 0 0 2px rgb(255 255 255 / 10%);
      opacity: 0;
      pointer-events: none;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      content: '';
      box-sizing: content-box;
      transform: scale(0.9);
    }
  }
  &:hover {
    .in {
      background-color: rgba(255, 255, 255, 1);
      transform: scale(0.8);
    }
    .out {
      border: 5px solid rgba(255, 255, 255, 1);
      transform: scale(0.9);
      color: @main-color-cl-08;
      &:after {
        -webkit-animation: sonarEffect 1.2s ease-out;
        -moz-animation: sonarEffect 1.2s ease-out;
        animation: sonarEffect 1.2s ease-out;
      }
    }
  }
 }

.custom-hotspot > .hotspot {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background: url(../../../icons/hotspot-icon.svg) no-repeat 50% 50% @main-color-cl-01;
  background-size: 45px 45px;
  animation: none;
  cursor: pointer;

  > div {
      visibility: hidden;
  }

  &:after,
  &:before {
    content: '';
    position: absolute;
    border: 2px solid @main-color-cl-31;
    left: -30px;
    opacity: 0;
    right: -30px;
    top: -30px;
    bottom: -30px;
    border-radius: 50%;
    animation: pulse 2s linear infinite;
  }

  &:after {
    animation-delay: 1.25s;
  }
}

@-webkit-keyframes sonarEffect {
  0% {
    opacity: 0.5;
  }
  20% {
    opacity: 0.8;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px rgba(37, 170, 225, .8), 0 0 0 10px rgba(255, 255, 255, 0.5);
  }
  100% {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px rgba(37, 170, 225, .8), 0 0 0 10px rgba(255, 255, 255, 0.5);
    -webkit-transform: scale(1.5);
    opacity: 0;
  }
}

@-moz-keyframes sonarEffect {
  0% {
    opacity: 0.5;
  }
  20% {
    opacity: 0.8;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px rgba(37, 170, 225, .8), 0 0 0 10px rgba(255, 255, 255, 0.5);
  }
  100% {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px rgba(37, 170, 225, .8), 0 0 0 10px rgba(255, 255, 255, 0.5);
    -moz-transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes sonarEffect {
  0% {
    opacity: 0.5;
  }
  20% {
    opacity: 0.8;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px rgba(37, 170, 225, .8), 0 0 0 10px rgba(255, 255, 255, 0.5);
  }
  100% {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px rgba(37, 170, 225, .8), 0 0 0 10px rgba(255, 255, 255, 0.5);
    transform: scale(1.5);
    opacity: 0;
  }
}