@import "../../variables";

.details_charts {
  .chart_box {
    @media @min1199 {
      margin: 0 200px;
    }
    .temp_row {
      display: grid;
      grid-template-columns: auto auto 1fr;
      grid-template-rows: 1fr;
      gap: 0 80px;
      margin: 80px 0 100px 0;
      > div {
        display: flex;
        flex-direction: column;
        .temp_box {
          margin: 0 auto;
        }
      }
      .temp_box {
        display: flex;
        margin-right: 75px;
        span:last-child {
          color: @main-color-cl-14;
          display: block;
          font-size: @font-h6;
          margin-bottom: auto;
        }
      }
      .time_box {
        color: @main-color-cl-15;
        position: relative;
        padding-left: 80px;
        &:before {
          content: '';
          display: block;
          position: absolute;
          height: 40px;
          width: 2px;
          left: 0;
          background: @main-color-cl-18;
        }
        .time {
          color: @main-color-cl-01;
        }
      }
      @media (max-height: 1000px) {
        margin: 40px 0 50px 0;
      }
    }
    .chart {
      min-width: 50vw;
      margin-bottom: 50px;
      height: 325px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      @media (max-height: 1000px) {
        margin-bottom: 25px;
      }
    }
  }

  .value, .temp_value {
    font-size: @font-h6;
    color: @main-color-cl-01;
    margin-right: 5px;
    & ~ span {
      margin-top: 3px;
    }
  }
  .temp_value {
    font-size: @font-h4;
    line-height: 100%;
  }
  span {
    color: @main-color-cl-14;
    text-transform: uppercase;
  }

  hr {
    opacity: .1;
    background: @main-color-cl-01;
  }
}