.box {
  padding: 24px;
  border-radius: 10px;
  outline: none;
  width: auto !important;
  max-width: 90vw;
  overflow: auto;
}
.box .box_header {
  margin-bottom: 15px;
}
.box .box_header h3 {
  margin-top: -10px;
  font-size: 20px !important;
  font-family: 'Ubuntu', sans-serif;
}
.box .box_header .cancel_btn {
  position: absolute;
  color: #00205B;
  right: 0;
  top: 0;
  padding: 10px;
}
.box .box_header .cancel_btn svg {
  width: 37px;
  height: 37px;
}
.box.secondary_box {
  padding: 60px 65px 40px;
}
.box.secondary_box .box_header {
  margin-bottom: 40px;
}
.box.secondary_box .box_header h5 {
  font-size: 34px !important;
  font-family: 'Ubuntu', sans-serif;
}
