.label {
  color: #00205B;
  size: 18px;
}
.modal_card {
  border: 1px solid #00205B !important;
  height: 52px !important;
  min-width: 155px;
}
.error_text {
  color: #D02B20;
  position: relative;
  display: block;
  text-align: left;
  margin: -35px 0 0;
}
.add_form_wrap {
  height: calc(100vh - 300px);
  padding-bottom: 150px;
}
.add_form_wrap .add_form {
  background: #FFFFFF;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 80px;
}
