@import "../variables";
@import "../functions";

.filters_wrap {
  .iconFilters {
    width: 20px;
    height: 20px;
  }
  & > * {
    .mx(10px) !important;
    min-width: auto !important;
  }
  & > *:first-child { margin-left: 0 !important;}
  & > *:last-child { margin-right: 0 !important;}
  .filter_btn_wrap {
    transition: @transition05;
    overflow: hidden;
    //width: calc(100% + 40px);
    &.warning_filter {
      width: 125px;
    }
    &.hide_filter {
      width: 0;
      //height: 0;
      margin: 0 !important;
      opacity: 0;
      pointer-events: none;
    }
  }
  .filter_btn {
    color: @main-color-cl-13;
    background: transparent;
    font-size: @font-span;
    border: 1px solid @main-color-cl-13;
    box-sizing: border-box;
    border-radius: 40px;
    transition: @transition02;
    padding: 5px 20px;
    height: auto;
    text-decoration: none;
    
    &:hover, &:focus-within {
      color: @main-color-cl-01;
      background: transparent;
      border-color: @main-color-cl-01;
    }
    &.active_btn {
      color: @main-color-cl-08;
      background: @main-color-cl-01;
      border-color: @main-color-cl-01;
    }
  }
  .reset_btn {
    color: @main-color-cl-01;
    transition: @transition02;
    min-width: 170px;
    &.hide {
      opacity: 0;
      pointer-events: none;
    }
  }

  .clearIcon { 
    display: none;
  }
}
