@import "functions";
@import "variables";

.fade {
  &-enter {
    opacity: 0;
    z-index: 1;
    pointer-events: none;
    & > * { pointer-events: auto; }
    &.fade-enter-active {
      opacity: 1;
      transition: opacity 1s ease-out;
      pointer-events: none;
      & > * { pointer-events: auto; }
    }
  }
  &-exit {
    opacity: 1;
    pointer-events: none;
    & > * { pointer-events: auto; }
    &.fade-exit-active {
      opacity: 0;
      transition: opacity 1s ease-out;

      position: absolute;
      top: 0;
      height: 100vh;
      width: 100vw;

      overflow: inherit;
      pointer-events: none;
      & > * { pointer-events: auto; }
    }
  }
}

.fade-tabs {
  &-enter {
    opacity: 0;
    z-index: 1;
    pointer-events: none;
    & > * { pointer-events: auto; }
    &.fade-tabs-enter-active {
      opacity: 1;
      transition: opacity .5s ease-out;
      pointer-events: none;
      & > * { pointer-events: auto; }
    }
  }
  &-exit {
    opacity: 1;
    pointer-events: none;
    & > * { pointer-events: auto; }
    &.fade-tabs-exit-active {
      opacity: 0;
      transition: opacity .5s ease-out;
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      overflow: inherit;
      pointer-events: none;
      & > * { pointer-events: auto; }
    }
  }
}