@media (min-width: 1199px) {
  .details_charts .chart_box {
    margin: 0 200px;
  }
}
.details_charts .chart_box .temp_row {
  display: grid;
  grid-template-columns: auto auto 1fr;
  grid-template-rows: 1fr;
  gap: 0 80px;
  margin: 80px 0 100px 0;
}
.details_charts .chart_box .temp_row > div {
  display: flex;
  flex-direction: column;
}
.details_charts .chart_box .temp_row > div .temp_box {
  margin: 0 auto;
}
.details_charts .chart_box .temp_row .temp_box {
  display: flex;
  margin-right: 75px;
}
.details_charts .chart_box .temp_row .temp_box span:last-child {
  color: #8E8EA9;
  display: block;
  font-size: 24px;
  margin-bottom: auto;
}
.details_charts .chart_box .temp_row .time_box {
  color: #A5A5BA;
  position: relative;
  padding-left: 80px;
}
.details_charts .chart_box .temp_row .time_box:before {
  content: '';
  display: block;
  position: absolute;
  height: 40px;
  width: 2px;
  left: 0;
  background: #EAEAEF;
}
.details_charts .chart_box .temp_row .time_box .time {
  color: #00205B;
}
@media (max-height: 1000px) {
  .details_charts .chart_box .temp_row {
    margin: 40px 0 50px 0;
  }
}
.details_charts .chart_box .chart {
  min-width: 50vw;
  margin-bottom: 50px;
  height: 325px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}
@media (max-height: 1000px) {
  .details_charts .chart_box .chart {
    margin-bottom: 25px;
  }
}
.details_charts .value,
.details_charts .temp_value {
  font-size: 24px;
  color: #00205B;
  margin-right: 5px;
}
.details_charts .value ~ span,
.details_charts .temp_value ~ span {
  margin-top: 3px;
}
.details_charts .temp_value {
  font-size: 48px;
  line-height: 100%;
}
.details_charts span {
  color: #8E8EA9;
  text-transform: uppercase;
}
.details_charts hr {
  opacity: 0.1;
  background: #00205B;
}
