@import "../variables";

.wrap {
  @media @min992 {
    margin-top: calc(85vw - 580px - 166px - 50px - 24px);
  }
  @media (min-width: 1200px) {
    margin-top: calc(80vw - 580px - 166px - 50px - 24px);
  }
  @media (min-width: 1440px) {
    margin-top: calc(75vw - 580px - 166px - 50px - 24px);
  }
  @media (min-width: 1600px) {
    margin-top: calc(1150px - 580px - 166px - 50px - 24px);
  }
  @media (min-width: 1900px) {
    margin-top: calc(1150px - 580px - 166px - 50px - 24px);
  }
  @media (min-width: 2300px) {
    margin-top: calc(1150px - 580px - 166px - 50px - 24px);
  }
  @media (min-width: 3000px) {
    margin-top: calc(1200px - 580px - 166px - 50px - 24px);
  }
  @media (min-width: 3300px) {
    margin-top: calc(1250px - 580px - 166px - 50px - 24px);
  }
  @media (min-width: 3500px) {
    margin-top: calc(1300px - 580px - 166px - 50px - 24px);
  }
  @media (min-width: 3800px) {
    margin-top: calc(1350px - 580px - 166px - 50px - 24px);
  }
  @media (min-width: 4200px) {
    margin-top: calc(1400px - 580px - 166px - 50px - 24px);
  }
  .briefInfo {
    position: relative;
    margin-bottom: 30px;
    color: @main-color-cl-01;
    transition: @transition02;
    z-index: 2;
    pointer-events: none;
    opacity: 0;
    &.hidden {
      opacity: 0;
    }
    span, a {
      width: auto;
      margin-right: 10px;
      &:not(:first-child) {
        margin-left: 10px;
      }
      &.bold {
        text-decoration: underline !important;
        font-weight: bold;
        color: @main-color-cl-01 !important;
      }
    }
  }
  .container {
    transition: @transition02;
    position: relative;
    background: white;
    box-shadow: @main-form-shadow;
    border-radius: 5px;
    min-height: 220px;
    margin-bottom: 110px;
    padding: 16px;
    color: @main-color-cl-01;
    z-index: 2;
    .image_wrap {
      width: 275px;
      max-height: 190px;
      overflow: hidden;
      padding-left: 0;
      .lazy_load_image {
        display: flex !important;
        align-items: center;
        justify-content: center;
        width: 251px;
        height: 188px;
        overflow: hidden;
        border-radius: 8px;
        img {
          margin: auto 0;
          height: 100%;
          width: auto;
        }
      }
    }
  }

  .title {
    font-weight: 500;
  }
}
