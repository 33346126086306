.container {
  margin-top: 210px;
}
.container .divider {
  max-width: 370px;
  border: 0.5px solid #C0C0CF;
}
@media (max-width: 1699px) {
  .container .divider.divider_container {
    margin-top: 96px;
  }
}
