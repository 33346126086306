@import "../variables";

.footer {
  margin-bottom: 160px;
  img {
    width: 83px;
  }
  hr {
    margin: 115px 0 80px 0;
  }
  .short_line {
    background: @main-color-cl-01;
    width: 40px;
    height: 4px;
    margin: 50px 0 40px;
    opacity: 1;
  }
  span {
    color: @main-color-cl-01;
    display: block;
    margin-bottom: 20px;
    font-weight: 500;
  }
  a {

  }
}