@import "../../variables";

.modal_sensor {
  z-index: 2;
  position: absolute;
  top: 25%;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: @transition02;
  & > div {
    margin: 0;
  }
  &.show {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
}

.container {
  //margin: 0 40px 10px 0;
  margin: 0 -1px 10px 0;
  background: white;
  box-shadow: @secondary-shadow;
  border-radius: 10px;
  padding: 29px 12px 26px;
  text-align: center;
  color: @main-color-cl-01;
  width: 233px;
  height: 366px;
  transition: @transition05;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &.isModal {
    margin: 0;
  }
  .name_wrap {
    min-height: 54px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .name {
      width: 100%;
      font-weight: 500;
      margin: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}