input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"] {
  background: #F3F3F3;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid transparent;
  width: 400px;
  height: 52px;
  transition: all 0.2s ease;
}
input[type="text"]:hover,
input[type="number"]:hover,
input[type="email"]:hover,
input[type="password"]:hover,
input[type="text"]:active,
input[type="number"]:active,
input[type="email"]:active,
input[type="password"]:active,
input[type="text"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="password"]:focus {
  border-color: #C0C0CF;
  background: #F3F3F3;
  box-shadow: none;
}
.btn-primary {
  background: #00205B !important;
  border-radius: 8px !important;
  height: 52px;
  color: #FFFFFF !important;
  border: none !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
}
.btn-primary:hover {
  background-color: #002B7A !important;
}
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  box-shadow: none !important;
}
.btn-primary:disabled {
  cursor: no-drop;
  opacity: 0.5;
}
.btn-secondary {
  background: #F6F6F9 !important;
  border-radius: 8px !important;
  height: 52px;
  color: #666687 !important;
  border: none !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
}
.btn-secondary:hover {
  background-color: #EAEAEF !important;
}
.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:focus {
  box-shadow: none !important;
}
.btn-secondary:disabled {
  cursor: no-drop;
  opacity: 0.5;
}
.btn-b {
  height: 52px;
}
.btn-m {
  height: 40px;
}
.btn-s {
  height: 30px;
}
.btn-modal-primary {
  width: 125px;
  height: 40px;
}
.btn-modal-secondary {
  width: 125px;
  height: 40px;
  border: 1px solid #666687 !important;
  background-color: transparent;
}
.input-modal {
  width: 100% !important;
}
a {
  color: #32324D !important;
  text-decoration: none !important;
}
.flex {
  display: flex;
}
.flex-imp {
  display: flex !important;
}
.flex-top-center {
  display: flex;
  align-items: center;
}
.flex-top-center-imp {
  display: flex !important;
}
.flex-top-center {
  display: flex;
  align-items: center;
}
.flex-top-center-imp {
  display: flex !important;
}
@media (max-width: 993px) {
  .flex-top-center-tablet {
    display: flex !important;
    align-items: center !important;
  }
  .flex-top-center-tablet-imp {
    display: flex !important;
  }
}
.flex-left-center {
  display: flex;
  justify-content: center;
}
.flex-left-center-imp {
  display: flex !important;
}
.flex-left-center {
  display: flex;
  justify-content: center;
}
.flex-left-center-imp {
  display: flex !important;
}
@media (max-width: 993px) {
  .flex-left-center-tablet {
    display: flex !important;
    justify-content: center !important;
  }
  .flex-left-center-tablet-imp {
    display: flex !important;
  }
}
@media (min-width: 992px) {
  .flex-left-center-desktop {
    display: flex !important;
    justify-content: center !important;
  }
  .flex-left-center-desktop-imp {
    display: flex !important;
  }
}
.flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}
.flex-center-imp {
  display: flex !important;
}
.flex-center-imp {
  display: flex !important;
}
@media (max-width: 993px) {
  .flex-center-tablet {
    display: flex !important;
    align-items: center !important;
  }
  .flex-center-tablet-imp {
    display: flex !important;
  }
}
.flex-center-imp {
  display: flex !important;
}
.flex-center-imp {
  display: flex !important;
}
@media (max-width: 993px) {
  .flex-center-tablet {
    display: flex !important;
    justify-content: center !important;
  }
  .flex-center-tablet-imp {
    display: flex !important;
  }
}
@media (min-width: 992px) {
  .flex-center-desktop {
    display: flex !important;
    justify-content: center !important;
  }
  .flex-center-desktop-imp {
    display: flex !important;
  }
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-column-imp {
  display: flex !important;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-column-imp {
  display: flex !important;
}
@media (max-width: 993px) {
  .flex-column {
    align-items: center;
  }
}
@media (max-width: 993px) {
  .flex-column-tablet {
    display: flex !important;
    flex-direction: column !important;
  }
  .flex-column-tablet-imp {
    display: flex !important;
  }
}
@media (max-width: 560px) {
  .flex-column-mobile {
    display: flex !important;
    flex-direction: column !important;
  }
  .flex-column-mobile-imp {
    display: flex !important;
  }
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-row-imp {
  display: flex !important;
}
@media (min-width: 992px) {
  .flex-row-d {
    display: flex;
    align-items: center;
    max-width: 990px;
  }
  .flex-row-d-imp {
    display: flex !important;
  }
  .flex-row-d > * {
    margin-left: 5px;
    margin-right: 5px;
  }
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.flex-between-imp {
  display: flex !important;
}
@media (min-width: 992px) {
  .flex-between-desktop {
    display: flex;
    justify-content: space-between;
  }
  .flex-between-desktop-imp {
    display: flex !important;
  }
}
.flex-evenly {
  display: flex;
  justify-content: space-evenly;
}
.flex-evenly-imp {
  display: flex !important;
}
.flex-around {
  display: flex;
  justify-content: space-around;
}
.flex-around-imp {
  display: flex !important;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}
.flex-end-imp {
  display: flex !important;
}
.display-none {
  display: none;
}
.h-500 {
  height: 500px;
}
.min-h-200 {
  min-height: 200px;
}
.py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}
.py-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}
.px-7 {
  padding-left: 7px !important;
  padding-right: 7px !important;
}
.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.px-25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}
.pt-15 {
  padding-top: 15px;
}
.pt-50 {
  padding-top: 50px;
}
.pt-70 {
  padding-top: 70px;
}
.pb-40 {
  padding-bottom: 40px;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-24 {
  margin-bottom: 24px !important;
}
.mb-35 {
  margin-bottom: 35px !important;
}
.mb-40 {
  margin-bottom: 40px !important;
}
.mb-50 {
  margin-bottom: 50px !important;
}
.mb-70 {
  margin-bottom: 70px !important;
}
.mb-120 {
  margin-bottom: 120px !important;
}
.mb-140 {
  margin-bottom: 140px !important;
}
.fw-normal {
  font-weight: normal;
}
.fw-bold {
  font-weight: 600;
}
.text-keft {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-initial {
  text-align: initial;
}
.cursor-pointer {
  cursor: pointer;
}
.pointer-event-none {
  pointer-events: none;
}
.user-select-none {
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}
.user-select-auto {
  -moz-user-select: auto;
  -khtml-user-select: auto;
  user-select: auto;
}
.user-select-none {
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}
.user-select-auto {
  -moz-user-select: auto;
  -khtml-user-select: auto;
  user-select: auto;
}
.color-red {
  color: #D02B20;
}
.color-main-color-primary {
  color: #00205B;
}
.color-secondary-color {
  color: #32324D;
}
.bg-color-main-color-form-bg {
  background-color: #FFFFFF;
}
.b-shadow {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}
.container-box {
  border-radius: 10px;
  width: 100%;
}
.container-box-shadow {
  border-radius: 10px;
  width: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}
.container-header {
  margin: 0 0 -10px;
  font-weight: 500;
  color: #00205B;
}
.listItem {
  border-bottom: 1px solid #EAEAEF;
  min-height: 40px;
}
.listItem > div[role="button"] {
  min-height: inherit;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.listItem span {
  font-size: 16px !important;
  text-transform: capitalize;
}
.opacity-0 {
  opacity: 0 !important;
}
.opacity-1 {
  opacity: 1 !important;
}
@keyframes _show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes _hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes _fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes pulse {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(0.7);
    opacity: 0;
  }
}
.usage_map_wrap {
  margin-top: -168px !important;
  max-width: 100vw;
  overflow: hidden;
  height: 1200px;
  position: absolute;
  top: 0;
  left: 0;
  max-height: 1200px;
}
@media (min-width: 3000px) {
  .usage_map_wrap {
    height: 1250px;
    max-height: 1250px;
  }
}
@media (min-width: 3300px) {
  .usage_map_wrap {
    height: 1300px;
    max-height: 1300px;
  }
}
@media (min-width: 3500px) {
  .usage_map_wrap {
    height: 1350px;
    max-height: 1350px;
  }
}
@media (min-width: 3800px) {
  .usage_map_wrap {
    height: 1400px;
    max-height: 1400px;
  }
}
@media (min-width: 4200px) {
  .usage_map_wrap {
    height: 1450px;
    max-height: 1450px;
  }
}
.usage_map_wrap .sensor_style {
  transition: all 0.5s ease;
  z-index: 2;
  cursor: pointer;
}
.usage_map_wrap .sensor_style:before {
  content: '';
  background: url("../../../images/performance/sensors/sensor_hover.png") no-repeat;
  width: 70%;
  height: 70%;
  display: block;
  position: absolute;
  background-size: contain !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.5s ease;
  pointer-events: none;
  cursor: pointer;
}
.usage_map_wrap .sensor_style.status_bad:before {
  background: url("../../../images/performance/sensors/sensor_warning.png") no-repeat;
  opacity: 1;
  animation: _show 0.5s;
}
.usage_map_wrap .sensor_style.status_bad:after {
  content: '';
  background: url("../../../images/performance/sensors/pulse_animation.svg") no-repeat;
  animation: _show 0.5s;
  opacity: 1;
  position: absolute;
  top: -14px;
  left: -25px;
  width: 84px;
  height: 50px;
  z-index: 333;
  pointer-events: none;
}
.usage_map_wrap .sensor_style:hover:before {
  opacity: 1;
}
.usage_map_wrap .sensor_style.status_bad:hover:before {
  filter: brightness(115%);
  transition: all 0.5s ease;
}
.usage_map_wrap.hidden_tooltips .sensor_style {
  opacity: 0;
  pointer-events: none;
}
.usage_map_wrap .renderElement_wrap {
  transition: all 0.2s ease;
}
.usage_map_wrap .animation {
  position: absolute;
  top: 3px;
  left: -7px;
  pointer-events: none;
}
.usage_map_wrap .tess {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}
.usage_map_wrap .hidden_tooltip {
  opacity: 0;
  pointer-events: none;
}
.usage_map_wrap .img_layer {
  transition: opacity 0.5s ease;
  pointer-events: none;
}
.usage_map_wrap .img_layer.hidden {
  opacity: 0;
}
.usage_map_wrap .usage_map,
.usage_map_wrap .img_layer {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
}
@media (min-width: 992px) {
  .usage_map_wrap .usage_map,
  .usage_map_wrap .img_layer {
    margin: -5% -30% 0 -30%;
    width: calc(100vw + 60%) !important;
    max-width: none;
  }
}
@media (min-width: 1200px) {
  .usage_map_wrap .usage_map,
  .usage_map_wrap .img_layer {
    margin: 0% -30% 0 -35%;
    width: calc(100vw + 50%) !important;
    max-width: none;
  }
}
@media (min-width: 1440px) {
  .usage_map_wrap .usage_map,
  .usage_map_wrap .img_layer {
    margin: -1% -25% 0 -25%;
    width: calc(100vw + 40%) !important;
    max-width: none;
  }
}
@media (min-width: 1700px) {
  .usage_map_wrap .usage_map,
  .usage_map_wrap .img_layer {
    margin: -1% -25% 0 -25%;
    width: calc(100vw + 40%) !important;
    max-width: none;
  }
}
@media (min-width: 1700px) {
  .usage_map_wrap .usage_map,
  .usage_map_wrap .img_layer {
    margin: -9% -20% 0 -20%;
    width: calc(100vw + 40%) !important;
    max-width: none;
  }
}
@media (min-width: 1900px) {
  .usage_map_wrap .usage_map,
  .usage_map_wrap .img_layer {
    margin: -9% -12% 0 -12%;
    width: calc(100vw + 24%) !important;
    max-width: none;
  }
}
@media (min-width: 2300px) {
  .usage_map_wrap .usage_map,
  .usage_map_wrap .img_layer {
    margin: -8% -3% 0 -3%;
    width: calc(100vw + 6%) !important;
    max-width: none;
  }
}
@media (min-width: 2700px) {
  .usage_map_wrap .usage_map,
  .usage_map_wrap .img_layer {
    margin: -9% 0 0 0;
    width: calc(100vw) !important;
    max-width: none;
  }
}
@media (min-width: 3300px) {
  .usage_map_wrap .usage_map,
  .usage_map_wrap .img_layer {
    margin: -10% 0 0 0;
    width: calc(100vw) !important;
    max-width: none;
  }
}
@media (min-width: 3500px) {
  .usage_map_wrap .usage_map,
  .usage_map_wrap .img_layer {
    margin: -11% 0 0 0;
    width: calc(100vw) !important;
    max-width: none;
  }
}
@media (min-width: 3800px) {
  .usage_map_wrap .usage_map,
  .usage_map_wrap .img_layer {
    margin: -12% 0 0 0;
    width: calc(100vw) !important;
    max-width: none;
  }
}
@media (min-width: 4200px) {
  .usage_map_wrap .usage_map,
  .usage_map_wrap .img_layer {
    margin: -13% 0 0 0;
    width: calc(100vw) !important;
    max-width: none;
  }
}
.usage_map_wrap * {
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}
.red_line,
.red_line_2 {
  position: absolute;
  background: #D02B20;
  z-index: 1;
}
.red_line {
  height: 110px;
  width: 2px;
  left: -5px;
  top: -44%;
}
.red_line_2 {
  width: 85px;
  height: 2px;
  left: -5px;
  top: -44%;
}
.render_lines {
  transition: all 0.2s ease;
}
.hidden_lines {
  opacity: 0;
  pointer-events: none;
}
.performance_mouse_hover {
  width: 100%;
  height: 100%;
  position: absolute;
}
.card_wrap {
  width: fit-content;
  height: fit-content;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.2s ease;
  position: absolute;
  z-index: 2;
  transform: translate(-45%, calc(-100% - 20px));
}
.card_wrap.show {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}
.prototype_sensor {
  z-index: 3;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.2s ease;
  transform: translate(-50%, -50%);
  left: -350%;
  top: -200%;
}
.prototype_sensor > div {
  margin: 0;
}
.prototype_sensor.show {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}
.clickable_area {
  height: 100%;
  width: 100%;
  z-index: 1;
  position: absolute;
}
.elements {
  z-index: 2;
  position: absolute;
}
.elements.cursor {
  cursor: pointer;
}
