@import "../../variables";

.card_btn {
  > div {
    transition: @transition02;
  }
  border-radius: @btn-br;
  &:hover {
     > div {
       background: mix(@main-color-cl-07, @main-color-cl-08, 50%);
     }
  }
}