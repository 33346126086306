@import "../../variables";

.back_btn {
  z-index: 2;
  min-width: 56px;
  min-height: 56px;
  border-radius: 50px;
  max-height: 56px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 15px;
  width: 56px !important;
  white-space: nowrap;
  overflow: hidden;
  transition: @transition02;
  border: none;
  &.light {
    background: @main-color-cl-19;
  }
  &.dark {
    background: @main-color-cl-01;
  }
  img {
    display: block;
    height: 24px;
    width: 24px;
  }
  span {
    margin: 0 15px 0 5px;
    font-weight: normal;
    font-size: 24px;
    opacity: 0;
    pointer-events: none;
    transition: @transition02;
    width: max-content;
    &.light {
      color: @main-color-cl-01;
    }
    &.dark {
      color: @main-color-cl-19;
    }
  }
  &:hover {
    width: 135px !important;
    span {
      opacity: 1;
    }
  }
}
