@import "../../variables";

.container {
    width: 200px;
    height: 200px;
    position: relative;

    .avatar {
        cursor: pointer;
        width: 200px;
        height: 200px;
        transition: @transition05;
        border: 1px solid @main-color-cl-16;
    }

    .avatar:hover {
        opacity: 0.7;
    }

    .label input[type="file"] {
        display: none;
    }

    .editImg {
        cursor: pointer;
        width: 24px;
        height: 24px;
        position: absolute;
        bottom: -11px;
        right: 0;
    }
}