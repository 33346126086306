@import "../../variables";

.container {
  margin-top: 65px;

  .grid {
    width: 788px;

    .grid_item {
      margin-top: 11px;
    }
  }

  .grid_container {
    @media (max-width: 1699px) {
      margin: 17px 0 0 394px;
    }
  }

  .profileImage {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 1px solid @main-color-cl-16;
  }
}