.confirm_form_wrap {
  height: calc(100vh - 300px);
  padding-bottom: 150px;
}
.confirm_form_wrap .confirm_form {
  background: #FFFFFF;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 60px 80px 80px;
}
.confirm_form_wrap .confirm_form h2 {
  color: #32324D !important;
  margin-bottom: 50px;
  font-weight: bold;
}
.confirm_form_wrap .confirm_form .label {
  color: #32324D !important;
  font-weight: bold;
}
.confirm_form_wrap .confirm_form .error_text {
  color: #D02B20;
  position: relative;
  display: block;
  text-align: center;
  margin: 5px 0 -20px;
}
