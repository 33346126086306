.status {
  border-radius: 10px;
  padding: 4.5px 3.5px;
  transition: all 0.2s ease;
}
.status.Pending {
  background: rgba(0, 0, 255, 0.15);
  color: #0000FF;
}
.status.Active {
  background: rgba(28, 231, 131, 0.15);
  color: #00BC4B;
}
.status.Disabled {
  background: rgba(208, 43, 32, 0.15);
  color: #D02B20;
}
.table_content {
  height: 96px;
  display: table-row !important;
  color: #00205B !important;
  cursor: auto !important;
}
.table_content > td {
  transition: all 0.2s ease;
  color: #00205B !important;
}
.table_content > td:first-child {
  border: none;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.table_content > td:last-of-type {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.table_content:last-child > td {
  border: none !important;
}
.table_content > span {
  border-radius: 8px;
}
.table_content button {
  color: #00205B;
  cursor: pointer;
  min-width: auto;
  text-transform: none;
}
.table_content button:hover {
  background: transparent;
}
.table_content button > div {
  pointer-events: none;
  color: inherit;
}
.table_content button img {
  width: 24px;
}
.table_content .select {
  margin-right: 5px;
}
.table_content .select div {
  padding-right: 0 !important;
}
.table_content .select svg {
  display: none;
}
.table_content .select fieldset {
  border: none;
}
.table_content .role:hover .select div,
.table_content .role:active .select div,
.table_content .role:focus .select div {
  text-decoration: underline;
}
.table_content:hover > td {
  background: #F6F8FC;
  border-color: transparent;
}
