@import "../../variables";

body {
  background: @main-color-cl-07;
}

@media (min-width: 1400px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: calc(100% - 120px);
  }
}
.nav_light {
  background: none !important;
}
