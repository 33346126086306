@import "../../variables";

.img360container {
  margin-left: auto;
  margin-bottom: 40px;
  width: 370px;
  height: 220px;
  padding: 4px;
  background: white;
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  box-shadow: @main-form-shadow;
  
  @media (max-width: 1200px) {
    width: 265px;
    height: 160px;
  }
  @media (max-width: 1024px) {
    margin-left: auto;
    margin-bottom: 20px;
    width: 80px;
    height: 58px;
    //background: none;
    border-radius: none;
  }

  .img360wrap {
    height: 100% !important;
    overflow: hidden;
    border-radius: 10px !important;
    position: relative !important;
    background: @main-color-cl-07;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    z-index: 1;

    //> div {
    //  transition: @transition05;
    //  opacity: 0;
    //}
    //&.show {
    //  > div {
    //    opacity: 1;
    //  }
    //}
  }

  .btn360 {
    display: none;
    @media(max-width: 1024px) {
      display: block;
      text-align: center;
      padding: 15px 0;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: @main-color-cl-01;
      color: @main-color-cl-08;
      border-radius: 10px;
      font-size: @font-span;
      cursor: pointer;
      z-index: 9;
    }
  }
}

.imageHotspot {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.modalContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
