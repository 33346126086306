@import "../variables";

.container {
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(4px);
  border-radius: 20px;
  padding: 5px 10px;
}

.tooltip {
  background: white;
  box-shadow: 0 5px 15px rgb(0 0 0 / 10%) !important;
  border-radius: 10px !important;
  border: none !important;
  span {
    color: @main-color-cl-01;
  }
  div:first-child:before {
    border: none;
  }
}

.button_container {
  padding: 0 8px 7px 8px;
  .btn_primary {
    background: @main-color-cl-19 !important;
    border-radius: 8px !important;
    height: 48px;
    color: @main-color-cl-13 !important;
    width: 100%;
    border: none !important;
    text-transform: none;
    padding: 15px 20px;
    &:hover, &:active, &:focus {
      box-shadow: none !important;
    }
  }
}

.language {
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin: 0 22px 0 30px;
  img {
    margin-right: 5px;
    pointer-events: none;
  }
  span {
    color: @main-color-cl-13;
    text-transform: uppercase;
  }
}

.text_container {
  padding: 28px 43px;
  color: @main-color-cl-01;
}

.popover_container {
  background-color: @main-color-cl-08;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgb(0 0 0 / 0.1) !important;
}

.link_container {
  padding: 11px 8px 10px 8px;
  display: flex;
  flex-direction: column;
  .link {
    color: @main-color-cl-10 !important;
    font-size: 14px;
    background: transparent !important;
    border-radius: 8px !important;
    height: 34px;
    width: 100%;
    border: none !important;
    text-transform: none;
    &:not(&:last-child) {
      margin-bottom: 10px;
    }
    &:hover, &:active, &:focus {
      box-shadow: none !important;
    }
  }
}

.avatar {
  width: 48px;
  height: 48px;
  transition: @transition05;
  border: 1px solid @main-color-cl-16;
}