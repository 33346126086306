@import "../../variables";

.accordion {
  min-height: 340px;
  position: relative;
  width: max-content;
  margin-left: auto;
  pointer-events: none;
  > * {
    pointer-events: auto;
  }
  
  .accordion_btn button {
    background: transparent;
    border: none !important;
    box-shadow: none !important;
    
    h3 {
      font-weight: 500;
      margin-left: 8px;
    }
    &:after {
      background: url("../../../icons/arrow.svg") no-repeat;
      background-position: center;
    }
  }
  .accordion_body {
    padding-top: 0;
  }
  .filter_container {
    width: 260px;
    background: white;
    overflow: hidden;
    position: relative;
    border-radius: 10px !important;
    box-shadow: @main-form-shadow;
    pointer-events: auto;
    z-index: 2;
    .list_group_item {
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom-color: @main-color-cl-18;
    }
  }

}
