.back_btn {
  margin-top: 18px !important;
}
.tabs {
  margin: 140px 0 -60px 0;
}
.tabs li > button {
  font-size: 20px !important;
  background: transparent !important;
}
.box {
  margin-bottom: -65px;
}
.box .hr {
  margin: 70px 0 70px;
}
.box h2 {
  font-weight: 400;
  margin-bottom: 0;
  line-height: inherit;
}
